import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'react-bootstrap';
import UserContext from '../../../context/UserContext';
import jwtAxios from '../../../utils/jwtAxios';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

const UserList = () => {

    const [currentPage,setCurrentPage] = useState(1);
    const [page,setPage] = useState({});
    const context = useContext(UserContext);

    useEffect(() => {
        getAllUsers();
    },[currentPage])

    const getAllUsers = () => {
        context.setLoading(true);
        jwtAxios.get(`/api/User?page=${currentPage}`,{context:context})
        .then(response => {
            setPage(response.data);
            context.setLoading(false);
        })
        .catch(err => {
            context.setLoading(false);
        })
    }

    const changePage = ({selected}) => {
        setCurrentPage(selected+1);
    }

    const listOfUsers = page.content ? 
    page.content.map(user => {
       return(<UserItem item={user} key={user.id} />)
    }) 
    : 
    [];

    if(listOfUsers.length === 0){
        return(<div>Brak danych.</div>)
    }

    return (
        <div className="users-tab" style={{marginTop: 20}}>
            <Table striped hover size="sm">
            <thead>
                <tr>
                <th>Email</th>
                <th>Nazwisko i Imie</th>
                <th>Data rejestracji</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {listOfUsers}
            </tbody>
            </Table>
            <div>
                <ReactPaginate 
                    previousLabel={<AiOutlineLeft size={20} />}
                    nextLabel={<AiOutlineRight size={20} />}
                    pageCount={page.totalPageCount}
                    onPageChange={changePage}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    containerClassName={"pagination-buttons"}
                    previousLinkClassName={"pagination-pervious"}
                    nextLinkClassName={"pagination-next"}
                    disabledClassName={"pagination-disabled"}
                    activeClassName={"pagination-active"}
                />
            </div>
        </div>
    )
}

export default UserList


const UserItem = ({item}) => {
    var creationDate = item.created.split("T");
    return(
            <tr>
                <td>{item.email}</td>
                <td>{item.surename} {item.name}</td>
                <td>{creationDate[0]} {creationDate[1]}</td>
                <td><Link to={`/adminPanel/user/${item.id}`}>Detale</Link> <Link to={`/adminPanel/user/edit/${item.id}`}>Edytuj</Link></td>
            </tr>
    )
}