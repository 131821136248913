import React from 'react'

const NotFound = () => {
  return (
    <div className="container" style={{marginTop: 40}}>
        <h1 style={{color: "red"}}>Uups!</h1>
        <h1 style={{fontSize: 25}}>
            Strona o takim adresie nie została odnaleziona!
        </h1>
        <p>Wygląda na to że nic nie ma pod tym adresem.</p>
    </div>
  )
}

export default NotFound