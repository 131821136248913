const ACTIONS ={
    LOAD: 'load',
    INPUT_VALUE:'inputValue',
    TOGGLE:'toggle'
}

const reducer = (state,action)=>{
    switch(action.type){
        case ACTIONS.LOAD:
            return {
                id:action.payload.id,
                contestName: action.payload.contestName,
                description: action.payload?.description ||  "", //inline null check, if null then empty string
                applicationOpenDate: action.payload.applicationOpenDate,
                applicationEndDate: action.payload.applicationEndDate,
                contestStartDate: action.payload.contestStartDate,
                localization: action.payload.localization,
                participatesLimit: action.payload.participatesLimit,
                allowedAnswers: action.payload.allowedAnswers,
                visibleAssignment: action.payload.visibleAssignment,
                status: action.payload.status,
                creationDate: action.payload.creationDate,
                leaderboardVisible: action.payload.leaderboardVisible,
                leaderboardDescription: action.payload.leaderboardDescription,
                contestRegulations: action.payload.contestRegulations || ""
            };
        case ACTIONS.INPUT_VALUE:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }; 
        case ACTIONS.TOGGLE :
            const temp = state[action.payload.name]
            return {
                ...state,
                [action.payload.name]: !temp
            };       
        default:
            return state   
    }
}

export default reducer
export {ACTIONS}