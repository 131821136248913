import { useLocation, Navigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../context/UserContext";

export default function AuthRoute({children,role}){
    const { userStatus,user } = useContext(UserContext);
    const location = useLocation()
    const userLogged = localStorage.getItem("userLogged");
    
    if(userLogged && !userStatus.isLogged){
        return <LoadingPage/>;
    }

    if(!userStatus.isLogged){
        return <Navigate to="/login" state={{ from: location }} replace  />
    }

    if(role){
       if(user.roles.includes(role)){
            return children
       }else{
        return <Navigate to="/403" state={{ from: location }} replace  />
       }
    }

    return children
}

function LoadingPage(){
    return <div>Loading Data ...</div>
}