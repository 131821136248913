import { useEffect,useContext, useReducer, useState } from "react"
import { Form, Container, Row } from 'react-bootstrap';
import { useParams,useNavigate } from "react-router-dom"
import jwtAxios from "../../../utils/jwtAxios";
import UserContext from "../../../context/UserContext";
import reducer,{ACTIONS} from "../../../reducer/ContestReducer";

import {imageUploadCallback, loadDataFromHtmlToDraft} from '../../../utils/richTextEditorImages';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftjsToHtml from "draftjs-to-html";

const initContest = {
  contestName: "",
  description: "",
  applicationOpenDate: "",
  applicationEndDate: "",
  contestStartDate: "",
  localization: "",
  participatesLimit: 0,
  allowedAnswers: false,
  visibleAssignment: false,
  leaderboardVisible: false,
  leaderboardDescription:"",
  status: "",
  creationDate: "",
  contestRegulations:""
}

export default function ConstestEdit(){
    const {id} = useParams()
    const [contest,dispatch] = useReducer(reducer,initContest)
    const context = useContext(UserContext)
    const navigate = useNavigate()

    const [editorStateForDescription, setEditorStateForDescription] = useState(EditorState.createEmpty())
    const [editorStateForLeaderboardDescription, setEditorStateForLeaderboardDescription] = useState(EditorState.createEmpty())
    const [editorStateForContestRegulations,setEditorStateForContestRegulations] = useState(EditorState.createEmpty())

    useEffect(()=>{
        fetchContest()
    },[])

    const fetchContest = ()=>{
        jwtAxios.get(`/api/Contest/${id}`,{context:context})
        .then(res=> {
            dispatch({type:ACTIONS.LOAD, payload:{ ...res.data}});
            loadDataFromHtmlToDraft(res.data.description,setEditorStateForDescription);
            loadDataFromHtmlToDraft(res.data.leaderboardDescription,setEditorStateForLeaderboardDescription)
            loadDataFromHtmlToDraft(res.data.contestRegulations,setEditorStateForContestRegulations)
        })
        .catch(err=>{
            if(err.response.status === 404){
                console.log('Not Found')
            }
            else{
                console.log(err)
            }
        })
    }

    const handleInputChange = (e)=>{
        dispatch({
            type: ACTIONS.INPUT_VALUE,
            payload:{
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    const handleToggle = (e)=>{
        dispatch({
            type: ACTIONS.TOGGLE,
            payload:{
                name: e.target.name
            }
        })
    }

    const handleSubmit = (e) => {
        contest.description = draftjsToHtml(convertToRaw(editorStateForDescription.getCurrentContent()))
        contest.leaderboardDescription = draftjsToHtml(convertToRaw(editorStateForLeaderboardDescription.getCurrentContent()))
        contest.contestRegulations = draftjsToHtml(convertToRaw(editorStateForContestRegulations.getCurrentContent()))
        jwtAxios.put('/api/Contest/Edit',contest,{context:context})
        .then(r=>navigate(`/adminPanel/contest/${contest.id}`))
        .catch(err=>{
            if(err.response.status = 400){
                alert("Wysłano pusty obiekt")
            }else if(err.response.status = 404){
                alert("Nie znaleziono konkursu")
            }
            else{
                console.log(err)
            }
        })
        
        e.preventDefault();
    }

    if(!contest.id){
        return(
            <div>
                Contest Not Found
            </div>
        )
    }

    return (
        <Container className="form-container register-form">
        <h2>Contest: {contest.id}</h2>
        <h3>Data Utworzenia: {contest.creationDate}</h3>
        <hr />
        <Form>
            <Form.Group controlId="form.contestName">
                <Row>
                    <Form.Label>Contest Name</Form.Label>
                    <Form.Control type="text" name="contestName" value={contest.contestName} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.description">
                    <Form.Label style={{fontWeight: 600}}>Opis:</Form.Label>
                    <div style={{border: "1px solid lightgray", padding: 10}}>
                    <Editor
                        editorState={editorStateForDescription}
                        editorStyle={{border: "1px solid", minHeight: "100px", overflow: "auto", maxHeight: "400px"}}
                        toolbar={{
                            image: {
                                uploadCallback: imageUploadCallback,
                                previewImage: true,
                            },
                        }}
                        onEditorStateChange={(editorState) => {
                            setEditorStateForDescription(editorState);
                        }}
                    />
                    </div>
            </Form.Group>
            <br />
            <Form.Group controlId="form.leaderboardDescription">
                    <Form.Label style={{fontWeight: 600}}>Leaderboard Opis:</Form.Label>
                    <div style={{border: "1px solid black", padding: 10}}>
                    <Editor
                        editorState={editorStateForLeaderboardDescription}
                        editorStyle={{border: "1px solid", minHeight: "100px", overflow: "auto", maxHeight: "400px"}}
                        toolbar={{
                            image: {
                                uploadCallback: imageUploadCallback,
                                previewImage: true,
                            },
                        }}
                        onEditorStateChange={(editorState) => {
                            setEditorStateForLeaderboardDescription(editorState);
                        }}
                    />
                    </div>
            </Form.Group>
            <Form.Group controlId="form.applicationOpenDate">
                <Row>
                    <Form.Label>Start Rejestracji</Form.Label>
                    <Form.Control type="date" name="applicationOpenDate" value={contest.applicationOpenDate.split('T')[0]} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.applicationEndDate">
                <Row>
                    <Form.Label>Koniec Rejstracji</Form.Label>
                    <Form.Control type="date" name="applicationEndDate" value={contest.applicationEndDate.split('T')[0]} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.contestStartDate">
                <Row>
                    <Form.Label>Dzień Konkursu</Form.Label>
                    <Form.Control type="datetime-local" name="contestStartDate" value={contest.contestStartDate.split('.')[0]} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.localization">
                <Row>
                    <Form.Label>Miejsce Konkursu</Form.Label>
                    <Form.Control type="text" name="localization" value={contest.localization} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.participatesLimit">
                <Row>
                    <Form.Label>Liczba uczestników</Form.Label>
                    <Form.Control type="number" name="participatesLimit" value={contest.participatesLimit} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.allowedAnswers">
                    <Form.Check type="checkbox" label="Przesyłanie Odpowiedzi" name="allowedAnswers" onChange={handleToggle} checked={contest.allowedAnswers} />
            </Form.Group>
            <Form.Group controlId="form.visibleAssignment">
                    <Form.Check type="checkbox" label="Widocznośc Zadań" name="visibleAssignment" onChange={handleToggle} checked={contest.visibleAssignment} />
            </Form.Group>
            <Form.Group controlId="form.leaderboardVisible">
                    <Form.Check type="checkbox" label="Widocznośc Leaderboard" name="leaderboardVisible" onChange={handleToggle} checked={contest.leaderboardVisible} />
            </Form.Group>
            <br/>
            <Form.Group controlId="form.status">
                    <Form.Label>Status</Form.Label>
                    <Form.Select aria-label="Grade" name="status" value={contest.status} onChange={handleInputChange}>
                        <option value="Ended">Zakończony</option>
                        <option value="Active">Aktywny</option>
                        <option value="Planned">Zaplanowany</option>
                    </Form.Select>
                </Form.Group> 
                <br/>
            <Form.Group controlId="form.contestRegulations">
                <Form.Label style={{fontWeight: 600}}>Regulamin Konkursu:</Form.Label>
                <div style={{border: "1px solid black", padding: 10}}>
                <Editor
                    editorState={editorStateForContestRegulations}
                    editorStyle={{border: "1px solid", minHeight: "100px", overflow: "auto", maxHeight: "400px"}}
                     toolbar={{
                        image: {
                            uploadCallback: imageUploadCallback,
                            previewImage: true,
                        },
                    }}
                    onEditorStateChange={(editorState) => {
                        setEditorStateForContestRegulations(editorState);
                    }}
                />
                </div>
            </Form.Group>
                
            <br />
            <div>
               <button className="custom-button" onClick={handleSubmit}>Zapisz</button>
            </div>
        </Form>
    </Container>)
}