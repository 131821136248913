import React from 'react';
import axios from 'axios';
import { Form, Container, Row } from 'react-bootstrap';
import UserContext from '../../context/UserContext';
import { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import NotificationContext,{NotificationType} from '../../context/NotificationContext';

const Login = () => {

    const navigate = useNavigate();
    const { login } = useContext(UserContext);

    const notification = useContext(NotificationContext);

    const [loginValues,setLoginValues] = useState({
        email: "",
        password: ""
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginValues(pervValue => {
            return {...pervValue, [name]: value }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = await login(loginValues);

        if(result){
            notification(result,NotificationType.ERROR);
        }
        
        if(!result){
            navigate("/");
        }  
    }

  return (
        <Container className="form-container">
            <h2>Zaloguj się.</h2>
            <hr />
            <Form>
                <Form.Group controlId="form.Email">
                    <Row>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Email" onChange={handleInputChange} value={loginValues.email}></Form.Control>
                    </Row>
                </Form.Group>
                <Form.Group controlId="form.Login">
                    <Row style={{marginBottom: 5}}>
                        <Form.Label>Hasło</Form.Label>
                        <Form.Control type="password" placeholder="Hasło" name="password" onChange={handleInputChange} value={loginValues.password}></Form.Control>
                    </Row>
                </Form.Group>
                <div>
                <Link to="/restore" style={{color: "#df9705", fontSize: 14, fontWeight: 600, marginLeft: "21%", float: 'right'}}>Zapomniałeś hasła?</Link>
                </div>
                
                <br /><br/>
                <div className="text-center">
                <button className="custom-button" onClick={handleSubmit}>Zaloguj</button>
                </div>
            </Form>
        </Container>
  )
}

export default Login