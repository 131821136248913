import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate ,Link} from 'react-router-dom'
import AssignmentList from '../assignment/AssignmentList';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import UserContext from '../../context/UserContext';
import { Button } from 'react-bootstrap';
import jwtAxios from '../../utils/jwtAxios';
import parser from 'html-react-parser';
import AnswerModal from '../answer/AnswerModal';

const ContestDetails = () => {

  const {id} = useParams();
  const [contest, setContest] = useState({});
  const navigate = useNavigate();
  const [isRegistered,setIsRegister] = useState(false)
  const [registration,setRegistration] = useState({})
  const context = useContext(UserContext)
  const {userStatus,user} = context
  const [leaderboardVisible,setLeaderboardVisible] = useState(false)
  const [leaderboard,setLeaderboard]= useState({})

  useEffect(() => {
    _getContestDetailsById();
  }, [])

  useEffect(()=>{
    if(userStatus.isLogged){
       checkRegistration(id,user.userid)
    }
  },[userStatus.isLogged])

  const _getContestDetailsById = () => {
    context.setLoading(true);
    axios.get('/api/Contest/'+id)
    .then(({data}) => {
      var contestStart = data.contestStartDate.split("T");
      const temp_contest = {
          id: id,
          applicationEndDate: data.applicationEndDate.split("T")[0],
          applicationOpenDate: data.applicationOpenDate.split("T")[0],
          contestName: data.contestName,
          contestStartDate: contestStart[0]+" "+contestStart[1],
          creationDate: data.creationDate.split("T")[0],
          description: data.description,
          localization: data.localization,
          participatesLimit: data.participatesLimit,
          contestStatus: data.status,
          visibleAssignment: data.visibleAssignment,
          allowedAnswers: data.allowedAnswers,
          isRegistrationOpen: data.isRegistrationOpen,
          participants: data.participantsCount,
          leaderboardVisible:data.leaderboardVisible,
          leaderboardDescription:data.leaderboardDescription
      }

      setContest(temp_contest);
      _getLeaderboard(temp_contest)
      context.setLoading(false);
    })
    .catch(err => { console.log(err); context.setLoading(false); })
  }

  const _getLeaderboard=(contest)=>{
    if(contest.leaderboardVisible){
    axios.get(`/api/Punctation/leaderboard/${id}`)
    .then(response=>setLeaderboard(response.data))
    .catch(err=>alert(err))
    }
  }

  const checkRegistration = (contestId,userId) =>{
    if(userStatus.isAdmin){
      setIsRegister(false)
      return
    }
    
    jwtAxios.get(`/api/ContestRegistration/registartionCheck?contestId=${contestId}&userId=${userId}`,{context:context})
    .then(res=>{
      setIsRegister(true)
      setRegistration(res.data)
    })
    .catch(err=>{
      if(err.response.status === 403){
          alert(err.response.data)
      }else if(err.response.status === 404){
        setIsRegister(false)
    }else{
        console.log(err)
      }
    })
  }

  const register = () =>{
    jwtAxios.post(`/api/ContestRegistration/${contest.id}`,{},{context:context})
    .then(res=>checkRegistration(res.data.contestId,res.data.userId))
    .catch(err=>{
      //TODO obsluzyc statusy
      console.log(err.response)
    })
  }

  const unregister = () =>{
    jwtAxios.delete(`/api/ContestRegistration/${registration.id}`,{context:context})
    .then(res=>checkRegistration(contest.id,user.userid))
    .catch(err=>{
      //TODO obsluzyc statusy
      console.log(err.response)
    })
  }

  const renderRegisterSection = () =>{
    if(contest.isRegistrationOpen){
      return(
        <>
         {contest.isRegistrationOpen && userStatus.isLogged && !isRegistered && !userStatus.isAdmin ? <Button onClick={register}>Zapisz się</Button>:null}
         {isRegistered ? <div><span>Jesteś zapisany</span><Button className='mx-2' onClick={unregister}>Zrezygnuj</Button></div>: null}
        </>
      )
    }

    return(
      <>
       {contest.isRegistrationOpen && userStatus.isLogged && !isRegistered && !userStatus.isAdmin ? <Button onClick={register}>Zapisz się</Button>:null}
       {isRegistered ? <div><span>Jesteś zapisany</span></div>: null}
      </>
    )
    
  }

  const renderContentSection = () =>{
    if(!leaderboardVisible){
      return(
      <>
      <div className="contest-title">
        <h1>
            {contest.contestName}
        </h1>
      </div>
      <div className="container">
      <br />
        <dl className="details-info">
          <div>
          <dd>Nazwa</dd>
          <dt>{contest.contestName}</dt>
          </div>
          <div>
          <dd>Lokalizacja</dd>
          <dt>{contest.localization}</dt>
          </div>
          <div>
          <dd>Start konkursu</dd>
          <dt>{contest.contestStartDate}</dt>
          </div>
          <div>
          <dd>Początek etapu zapisów</dd>
          <dt>{contest.applicationOpenDate}</dt>
          </div>
          <div>
          <dd>Koniec etapu zapisów</dd>
          <dt>{contest.applicationEndDate}</dt>
          </div>
        </dl>
       {renderRegisterSection()}
        <hr />
        <div>
          {contest.description ? parser(contest.description) : ""}
        </div>
        <div style={{float: "right", marginRight: 45}}>
          <Link to={`/contests/regulation/${contest.id}`} style={{color: "#df9705"}}>Regulamin Konkursu</Link>
        </div>
        <br/>
      </div>
      <div className="container-custom">
      <AssignmentList contestId={id} />
      {userStatus.isLogged? 
      userStatus.isAdmin?null:<AnswerModal  contest={contest} isRegistered={isRegistered}/>
      : 
      null
      }
       </div> 
      </>)
    }

    return(
    <>
    <div className="contest-title">
        <h1>
            {contest.contestName}
        </h1>
      </div>
      <div className="container">
          <div style={{marginTop: 40}}>
            {contest.leaderboardDescription ? parser(contest.leaderboardDescription) : ""}
          </div>
          <hr/>
          <p style={{fontWeight: 600}}>Tablica Wyników:</p>
          <table className="leaderboard-table">
          <tr className="leaderboard-header">
            <th>#</th>
            <th>Uczestnik</th>
            <th>Punkty</th>
          </tr>
            {leaderboard.userAndPoints.map((up,index)=>{
              if(user.userid === up.userId){
                return(
                  <tr key={up.userId} className="leaderboard-content" style={{fontWeight: 800}}>
                    <td><span className={`${index === 0 ? 'winner-index' : ''} ${index === 1 ? 'silver-index' : ''} ${index === 2 ? 'bronze-index' : ''}`} style={{padding: "2px 8px 2px 8px"}}>{index+1}</span></td>
                    <td>{up.surname} {up.name}</td>
                    <td>{up.totalPoints}</td>
                  </tr>
                )
              }
              return(
                <tr key={up.userId} className="leaderboard-content">
                  <td ><span className={`${index === 0 ? 'winner-index' : ''} ${index === 1 ? 'silver-index' : ''} ${index === 2 ? 'bronze-index' : ''}`} style={{padding: "2px 8px 2px 8px"}}>{index+1}</span></td>
                  <td>{up.surname} {up.name}</td>
                  <td>{up.totalPoints}</td>
                </tr>
              )
            })}
          </table>
      </div>
    </>
    )
  }

  return (
    <div className="contest-tab" style={{marginTop: 0}}>
      {renderContentSection()}
      
      <div className="backbutton-section">
        <br />
        <button onClick={() => navigate(`${contest.contestStatus === "Active" ? "/" : "/contests"}`)} className="custom-button home-button"><AiOutlineArrowLeft size={15}/>Wróć</button>
        {contest.leaderboardVisible?<button className="custom-button" style={{width: "150px",height:"45px", marginLeft: "15px"}} onClick={()=>setLeaderboardVisible(prevState=>!prevState)}>{leaderboardVisible?"Zobacz Konkurs":"Zobacz Leaderboard"} </button>: null}
      </div>
    </div>
  )
}



export default ContestDetails