import React, { useState, useEffect }  from 'react'
import { Container, Form, Row } from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Change = () => {

    const [params] = useSearchParams();
    const navigate = useNavigate();

    const token = params.get("token");
    const email = params.get("email");

    const [newPasswordValues, setNewPasswordValues] = useState({
        newpassword: "",
        confirmNewPassowrd: ""
    })
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPasswordValues(pervValue => {
            return {...pervValue, [name]: value }
        })
    }
    
    const changePassword = (e) => {
        e.preventDefault();
        if(newPasswordValues.newpassword !== newPasswordValues.confirmNewPassowrd){
            console.log("error")
            return 
        }

        const restorePasswordRequest = {
            email: email,
            token: token,
            newPassword: newPasswordValues.newpassword
        }

        axios.post(`api/Authenticate/newRestoredPassword`,restorePasswordRequest,{headers:{'Content-Type':'application/json'}})
        .then(_ => {
            navigate("/login");
        })
        .catch(err => {
            console.log(err);
        })
    }

  return (
        <Container className="form-container register-form">
            <h2>Utwórz nowe hasło.</h2>
            <p>Podaj nowe hasło.</p>
            <hr />
            <Form>
                <Form.Group controlId="form.Password">
                    <Row>
                        <Form.Label>Nowe Hasło</Form.Label>
                        <Form.Control type="password" placeholder="hasło" name="newpassword" value={newPasswordValues.newpassword} onChange={handleInputChange} ></Form.Control>
                    </Row>
                </Form.Group>
                <Form.Group controlId="form.ConfirmPassword">
                    <Row>
                        <Form.Label>Potwierdź Hasło</Form.Label>
                        <Form.Control type="password" placeholder="potwierdź hasło" name="confirmNewPassowrd" value={newPasswordValues.confirmNewPassowrd} onChange={handleInputChange} ></Form.Control>
                    </Row>
                </Form.Group>
                <br />
                <div>
                   <button className="custom-button" onClick={changePassword}>Zmień hasło</button>
                </div>
            </Form>
        </Container>
  )
}

export default Change