import React, { useState, useEffect, useContext } from 'react'
import ReactPaginate from 'react-paginate';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import jwtAxios from '../../../utils/jwtAxios';
import UserContext from '../../../context/UserContext';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const InstitutionList = () => {

    const [currentPage,setCurrentPage] = useState(1);
    const [page,setPage] = useState({});
    const context = useContext(UserContext);

    useEffect(() => {
        getAllInstitutions();
    },[currentPage])


    const getAllInstitutions = () => {
        context.setLoading(true);
        jwtAxios.get(`/api/Institution?page=${currentPage}`,{context:context})
        .then(response => {
            setPage(response.data)
            context.setLoading(false);
        })
        .catch(err => {
            context.setLoading(false);
        })
    }

    const deleteInstitutionById = (id) => {
        jwtAxios.delete(`/api/Institution/${id}`,{context:context})
        .then(response => {
            getAllInstitutions()
        })
        .catch(err => {

        })
    }

    const changePage = ({selected}) => {
        setCurrentPage(selected+1);
    }

    const listOfInstitutions = page.content ? 
    page.content.map(inst => {
       return(<InstitutionItem item={inst} deleteIns={deleteInstitutionById} key={inst.id} />)
    }) 
    : 
    [];

    if(listOfInstitutions.length === 0){
        return(<div>Brak danych.</div>)
    }

    return (
    <div className="users-tab" style={{marginTop: 20}}>
            <Table striped hover size="sm">
            <thead>
                <tr>
                <th>Nazwa Instytucji</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {listOfInstitutions}
            </tbody>
            </Table>
            <div>
                <ReactPaginate 
                    previousLabel={<AiOutlineLeft size={20} />}
                    nextLabel={<AiOutlineRight size={20} />}
                    pageCount={page.totalPageCount}
                    onPageChange={changePage}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    containerClassName={"pagination-buttons"}
                    previousLinkClassName={"pagination-pervious"}
                    nextLinkClassName={"pagination-next"}
                    disabledClassName={"pagination-disabled"}
                    activeClassName={"pagination-active"}
                />
            </div>
        </div>
    )
}

const InstitutionItem = ({item,deleteIns}) => {
    const id = item.id;
    return(
        <tr>
            <td>{item.institutionName}</td>
            <td><a href="#" onClick={() => {deleteIns(id)}}>Usuń</a> <Link to={`/adminPanel/institution/edit/${item.id}`} state={item.institutionName}>Edytuj</Link></td>
        </tr>
    )
}

export default InstitutionList