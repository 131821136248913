import React, {useEffect, useState, useContext} from 'react';
import jwtAxios from '../../../utils/jwtAxios';
import { useParams } from 'react-router-dom';
import UserContext from '../../../context/UserContext';

const UserDetails = () => {

    const context = useContext(UserContext);
    const [user,setUser] = useState({});
    const {id} = useParams();

    useEffect(() => {
        jwtAxios.get(`/api/User/${id}`,{context:context})
        .then(r=>{
            console.log(r.data);
            setUser(r.data)
        }).catch(err=>{
            if(err.response.status === 403){
                alert("Forbbiden")
            }
            if(err.response.status === 404){
                alert("User not found")
            }
            alert("something go wrong")
        })
    },[])  

  return (
    <div className="profile-tab" style={{marginTop: 20}}>
            <div className="container">
                <h1 className="h1--custom">Detale użytkownika: {user.email}</h1>
                <hr/>

                <dl className='details-info'>
                    <div>
                        <dd>Utworzony:</dd>
                        {user.created ? (<dt>{user.created.split("T")[0]} {user.created.split("T")[1]}</dt>) : (<dt>Brak danych</dt>)}
                    </div>
                    <div>
                        <dd>Email:</dd>
                        <dt>{user.email}</dt>
                    </div>
                    <div>
                        <dd>Imię:</dd>
                        <dt>{user.name}</dt>
                    </div>
                    <div>
                        <dd>Nazwisko:</dd>
                        <dt>{user.surename}</dt>
                    </div>
                    <div>
                        <dd>Nauka:</dd>
                        <dt>{user.institutionName?? "Brak"}</dt>
                    </div>
                    <div>
                        <dd>Email potwierdzony:</dd>
                        <dt>{user.isConfirm? 'true':'false'}</dt>
                    </div>
                </dl>

                <h2 >Roles:</h2>
                <ul>
                {context.user.roles.length > 0 ? 
                        context.user.roles.map(r=>{return<li key={r}>{r}</li>}):
                        null}
                </ul>
            </div>
        </div>
  )
}

export default UserDetails