import React, { useState } from 'react'
import { Outlet, Link, useLocation } from 'react-router-dom'
import { Nav } from 'react-bootstrap'

const AdminPanel = () => {

    const location = useLocation();

  return (
    <div className="admin-panel" style={{marginTop: 20}}>
        <div className="container admin-panel-container">
            <h1 className="h1--custom" style={{marginBottom: 20}}>Panel administracyjny</h1>

            <Nav variant="tabs" defaultActiveKey="/home">
            <Nav.Item>
                <Link className={`nav-link ${location.pathname.includes("contests") ? "active-tab" : ""}`} to="/adminPanel/contests">Konkursy</Link>
            </Nav.Item>
            <Nav.Item>
                <Link className={`nav-link ${location.pathname.includes("contest/add") ? "active-tab" : ""}`} to="/adminPanel/contest/add">Dodaj Konkurs</Link>
            </Nav.Item>
            <Nav.Item>
                <Link className={`nav-link ${location.pathname.includes("users") ? "active-tab" : ""}`} to="/adminPanel/users">Użytkownicy</Link>
            </Nav.Item>
            <Nav.Item>
                <Link className={`nav-link ${location.pathname.includes("institutions") ? "active-tab" : ""}`} to="/adminPanel/institutions">Instytucje</Link>
            </Nav.Item>
            </Nav>

            <Outlet />
        </div>  
    </div>
  )
}

export default AdminPanel