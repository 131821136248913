import React from 'react'
import { Link } from 'react-router-dom';
import TextLogo from './components/elements/TextLogo';

const Footer = () => {
  return (
    <div className="footer">    
        <div className="footer-content">
            <hr style={{margin: 0}} />
            <div className="footer-credits">
            <p><TextLogo /></p>
              <p>&copy; 2023 Eryk Beczek, Jakub Iwaniuk, Marcin Stępniak</p>
            </div>
            <div style={{float: "right", marginRight: 45}}>
              {/* <Link to="regulamin" style={{color: "#df9705"}}>Regulamin</Link>&nbsp; */}
              <Link to="deklaracja" style={{color: "#df9705"}}>Deklaracja dostępności</Link>
            </div>
        </div>
    </div>
  )
}

export default Footer