import React, {useReducer, createContext, useCallback} from 'react';
import {v4} from 'uuid';
import Notification from '../components/Notification';
import reducer,{ACTIONS} from '../reducer/NotificationReducer';

const NotificationContext = createContext();

export const NotificationType = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
}

export function NotificationContextProvider({children}){

    const [state,dispatch] = useReducer(reducer,[]);

    const addNotification = (msg,type) =>{
      dispatch({
        type: ACTIONS.ADD_NOTIFICATION,
        payload: {
            id: v4(),
            type: type,
            msg: msg
        }
    })
    }

    const memoDispatch = useCallback((msg,type)=>{
      addNotification(msg,type)
    },[])

  return (
    <NotificationContext.Provider value={memoDispatch}>
        <div className='notification-wrapper'>
            {state.map( n => {
                return <Notification dispatch={dispatch} key={n.id} {...n} />
            })}
        </div>
        {children}
    </NotificationContext.Provider>
  )
}

export default NotificationContext;