import React from 'react';
import { Table } from 'react-bootstrap';

const ParticipantsList = ({participants,removeRegistration,contestStatus}) => {
   if(participants.length !== 0){
        return (
            <Table responsive size="sm">
            <thead style={{background: "#333333", color:"white"}}>
                <tr>
                <th>Email</th>
                <th>Nazwisko i Imie</th>
                <th>Rozwiązanie</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {participants.map((item,i) => <ParticipantItem item={item} key={i} removeRegistration={removeRegistration} contestStatus={contestStatus}/>)}
            </tbody>
            </Table>
    )
   } 
   
   return(
       <div>
           Nikt się jeszcze nie zapisał.
       </div>
   )
}

const ParticipantItem = ({item,removeRegistration,contestStatus }) =>{
    return(
        <tr>
            <td>{item.user.email}</td>
            <td>{item.user.surname} {item.user.name}</td>
            <td>{item.answerSend ? <span style={{color: "green"}}>Wysłano</span> : <span style={{color: "red"}}>Nie Wysłano</span>}</td>
            <td>{contestStatus!=='Ended'?<button onClick={()=>removeRegistration(item.user.id,item.registrationId)}  className="admin-option-button" style={{backgroundColor: "rgb(211, 77, 77)"}}>Wyrzuć</button>:null}</td>
        </tr>
    )
}

export default ParticipantsList





