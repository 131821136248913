import { useEffect,useState,useContext } from "react";
import UserContext from "../../../context/UserContext";
import jwtAxios from "../../../utils/jwtAxios";
import { Link } from "react-router-dom";
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import ReactPaginate from 'react-paginate';


export default function AdminContestList(){
    const[currentPage,setCurrentPage]=useState(1)
    const[page,setPage] = useState({})
    const context = useContext(UserContext)

    useEffect(()=>{
        context.setLoading(true);
        jwtAxios.get(`/api/Contest?page=${currentPage}`,{context:context})
        .then(r=>{
            setPage(r.data)
            context.setLoading(false);
        }).catch(err=>{
            if(err.response.status === 404){
                setPage({})
            }else{
                console.log(err)
            }
            context.setLoading(false);
        })
    },[currentPage])


    const changePage = ({selected}) => {
        setCurrentPage(selected+1);
    }
    
    const listOfContest = page.content? page.content.map(c=>{return (<ContestItem item={c} key={c.id}/>)}) : [];

    if(listOfContest.length === 0){
        return(<div>No Data</div>)
    }

    return(
        <div className="contest-list" style={{marginTop: 20}}>
            {listOfContest}
            <hr/>
            <div>
                <ReactPaginate 
                    previousLabel={<AiOutlineLeft size={20} />}
                    nextLabel={<AiOutlineRight size={20} />}
                    pageCount={page.totalPageCount}
                    onPageChange={changePage}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    containerClassName={"pagination-buttons"}
                    previousLinkClassName={"pagination-pervious"}
                    nextLinkClassName={"pagination-next"}
                    disabledClassName={"pagination-disabled"}
                    activeClassName={"pagination-active"}
                />
            </div>
        </div>
    )
}

function ContestItem({item}){
    return(
        <div>
            <h1>Name: <Link to={`/adminPanel/contest/${item.id}`} className="details-link">{item.contestName}</Link></h1>
            <span>Contest Date: {item.contestStartDate.split('T')[0]} | </span>
            <span>Creation Date: {item.creationDate.split('T')[0]} | </span>
            <span>Status: {item.status}</span>
            <hr/>
        </div>
    )
}