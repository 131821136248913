import React, {useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes} from 'react-icons/fa';
import UserContext from './context/UserContext';
import TextLogo from './components/elements/TextLogo';
import { useContext } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { FaUserAlt } from 'react-icons/fa'

const Navbar = () => {

    const { logout, userStatus, user } = useContext(UserContext);
    const navigate = useNavigate();

    const [click,setClick] = useState(false);

    const signOut = () => {
        logout();
        navigate("/");
    }

    const handleClick = () =>{
        setClick(!click)
    }

    const closeNavbar = () => {
        setClick(false)
    }

  return (
    <div className="custom-navbar">
        <div className="custom-navbar-content">
                <Link onClick={() => closeNavbar()} to="/" className="logo-link"><h1 className="custom-nav-logo"><TextLogo /></h1></Link>
            <div className={`custom-nav-menu-list ${click ? "active" : ""}`}>
                <ul className="custom-nav-menu">

                    <li className="custom-nav-item">
                        <Link onClick={() => closeNavbar()} to="/kontakt">Kontakt</Link>
                    </li>
                    {userStatus.isAdmin?
                        <li className="custom-nav-item">
                            <Link onClick={() => closeNavbar()} to="/adminPanel/contests">Panel administracyjny</Link>
                        </li>
                        :
                        null}
                        {!userStatus.isAdmin ?
                        <li className="custom-nav-item">
                            <Link onClick={() => closeNavbar()} to="/contests">Konkursy</Link>
                        </li>
                        :
                        null
                        }
                        
                </ul>
                <ul className="custom-nav-menu nav-right">
                    {userStatus.isLogged ? 
                    <ul className="custom-nav-menu nav-right">
                    <li className="custom-nav-item">
                        <Link onClick={() => closeNavbar()} to="/profile"><span style={{fontWeight: 700}}><FaUserAlt style={{marginBottom: 3}} /> Witaj {user.username}!</span></Link>
                    </li>
                    <li className="custom-nav-item">
                        <Link  to="" onClick={() => {signOut(); closeNavbar()}}><FiLogOut /> Wyloguj</Link>
                    </li>
                    </ul>
                    :  
                    <ul className="custom-nav-menu nav-right">
                    <li className="custom-nav-item">
                        <Link onClick={() => closeNavbar()} to="/register">Zarejestruj</Link>
                    </li>
                    <li className="custom-nav-item">
                        <Link onClick={() => closeNavbar()} to="/login">Zaloguj</Link>
                    </li>
                    </ul>
                    }
                </ul>

            </div>
            
            <div className="custom-hamburger" onClick={handleClick}>
                {click ? <FaTimes size={20} style={{color: "#fff"}} /> : <FaBars size={20} style={{color: "#fff"}}/>}    
            </div>
        </div>
    </div>
  )
}

export default Navbar