import React, { useReducer, useEffect, useContext, useState } from 'react';
import jwtAxios from '../../../utils/jwtAxios';
import UserContext from '../../../context/UserContext';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Container, Row } from 'react-bootstrap';
import reducer, { ACTIONS } from '../../../reducer/UserReducer';
import axios from 'axios';




const initUser = {
    email: "",
    name: "",
    surename: "",
    created: "",
    institutionName: "",
    isConfirm: false
}

const UserEdit = () => {

    const {id} = useParams()
    const [user ,dispatch] = useReducer(reducer,initUser)
    const context = useContext(UserContext)
    const navigate = useNavigate()

    const [institutionList,setInstitutionList] = useState([])

    useEffect(() => {
        _getUserById();
    }, [])

    const _getUserById = () => {
        jwtAxios.get(`/api/User/${id}`,{context:context})
        .then(response => {
            dispatch({ type:ACTIONS.LOAD, payload: response.data })
        })
        .catch(err => {
            if(err.response.status === 403){
                alert("Forbbiden")
            }
            if(err.response.status === 404){
                alert("User not found")
            }
            alert("something go wrong")
        })
    }

    const handleInputChange = (e)=>{
        dispatch({
            type: ACTIONS.INPUT_VALUE,
            payload: {
                name: e.target.name,
                value: e.target.value 
            }
        })
    }

    const handleToggle = (e)=>{
        dispatch({
            type: ACTIONS.TOGGLE,
            payload: { name: e.target.name }
        })
    }

    const handleChange = (e) => {
        if(e.target.value!==""){
        axios.get(`/api/Authenticate/institutionSearch?searchTerm=${e.target.value}`)
        .then(response => {
            setInstitutionList(response.data)
        })
        .catch(err => alert('Something went wrong.'));
        }

        dispatch({ type:ACTIONS.INPUT_VALUE, payload: { name: "institutionName", value: e.target.value } })
    }

    const onSearch = (searchTerm) => {
        setInstitutionList([])

        dispatch({ type:ACTIONS.INPUT_VALUE, payload: { name: "institutionName", value: searchTerm } })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        jwtAxios.put(`/api/User/Edit`,user,{context:context})
        .then(() => {
            navigate('/adminPanel/users');
        })
        .catch(err => {
            console.log("something went wrong!");
        })
    }

    return (
            <Container className="form-container register-form">
            <h2>Edycja użytkowika</h2>
            <p>Data Utworzenia: {user.created.split("T")[0]}</p>
            <hr />
            <Form>
                <Form.Group controlId="form.email">
                    <Row>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="email" value={user.email} onChange={handleInputChange} ></Form.Control>
                    </Row>
                </Form.Group>
                <Form.Group controlId="form.name">
                    <Row>
                        <Form.Label>Imie</Form.Label>
                        <Form.Control type="text" name="name" value={user.name} onChange={handleInputChange} />
                    </Row>
                </Form.Group>
                <Form.Group controlId="form.surename">
                    <Row>
                        <Form.Label>Nazwisko</Form.Label>
                        <Form.Control type="text" name="surename" value={user.surename} onChange={handleInputChange} ></Form.Control>
                    </Row>
                </Form.Group>
                <Form.Group controlId="form.isConfirmed">
                        <Form.Check type="checkbox" label="Email potwierdzony" name="isConfirmed" onChange={handleToggle} checked={user.isConfirmed} />
                </Form.Group>
                <br/>
                <Form.Group controlId="form.institutionName">
                    <Form.Label>Placówka</Form.Label>
                    <Form.Control type="text" value={user.institutionName} name="institutionName" onChange={handleChange}></Form.Control> 
                    <div className="input-dropdown">
                        {
                        institutionList.map((item) => (
                        <div onClick={() => onSearch(item.institutionName)} className='dropdown-row' key={item.id}>
                            {item.institutionName}
                        </div>
                        ))
                        }
                    </div>
                </Form.Group>
                <br />
                <div>
                <button className="custom-button" onClick={handleSubmit}>Zapisz</button>
                </div>
            </Form>
        </Container>
    )
}

export default UserEdit