import { useEffect,useState,useContext } from "react";
import jwtAxios from "../utils/jwtAxios"
import UserContext from "../context/UserContext";



export default function Profile(){
    const[user,setUser] = useState({})
    const context = useContext(UserContext)
    
    useEffect(()=>{
            jwtAxios.get(`api/User/${context.user.userid}`,{context:context})
            .then(r=>{
                setUser(r.data)
            }).catch(err=>{
                if(err.response.status = 403){
                    alert("Forbbiden")
                }
                if(err.response.status = 404){
                    alert("User not found")
                }
                alert("something go wrong")
            })
    },[])
    return(
        <div className="profile-tab" style={{marginTop: 20}}>
            <div className="container">
                <h1 className="h1--custom">Mój profil:</h1>
                <hr/>

                <dl className="details-info">
                    <div>
                        <dd>Email:</dd>
                        <dt>{user.email}</dt>
                    </div>
                    <div>
                        <dd>Imię:</dd>
                        <dt>{user.name}</dt>
                    </div>
                    <div>
                        <dd>Nazwisko:</dd>
                        <dt>{user.surename}</dt>
                    </div>
                    <div>
                        <dd>Uczęszcza do:</dd>
                        <dt>{user.institutionName? user.institutionName : "Brak"}</dt>
                    </div>
                    <div>
                        <dd >Rola:</dd>
                        <dt>
                        {context.user.roles.length > 0 ? 
                                context.user.roles.map(r=>{return<p style={{fontWeight: 600}} key={r}>{r}</p>}):
                                null}
                        </dt>
                    </div>
                </dl>

                
            </div>
        </div>
    )
}