import React from 'react'

const AccessibilityStatement = () => {
  return (
    <div className="container" style={{marginTop: 20}}>
        <h1 className="h1--custom">Deklaracja dostępności</h1>
        <p id="a11y-wstep"><strong><span id="a11y-podmiot">Uniwersytet Przyrodniczo-Humanistyczny w Siedlcach</span></strong> zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do witryny <a href="https://kodigo.ii.uph.edu.pl/" id="a11y-url">kodigo.ii.uph.edu.pl/</a>.</p>
        <p>Data publikacji strony internetowej: <strong><span id="a11y-data-publikacja">2014-05-13</span></strong>.</p>
        <p>Data ostatniej istotnej aktualizacji: <strong><span id="a11y-data-aktualizacja">2022-11-23</span></strong>.</p>
        <p>Strona internetowa jest <strong><span id="a11y-status">częściowo zgodna</span></strong> z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych z powodu niezgodności lub wyłączeń wymienionych poniżej:</p>
        <ul>
          <li>brak atrybutu alt w niektórych obrazach</li>
          <li>zbyt mały kontrast w niektórych miejscach</li>
          <li>niektóre obiekty nie są właściwie opisane,</li>
          <li>użycie tabeli do layoutu strony</li>
          <li>brak pola label do pola formularza</li>
          <li>wyjustowany tekst</li>
        </ul>
        <p>Oświadczenie sporządzono dnia: <strong><span id="a11y-data-sporzadzenie">2022-11-23</span></strong>. Deklarację sporządzono na&nbsp;podstawie samooceny przeprowadzonej przez podmiot publiczny.</p>
        <p>Na stronie internetowej można korzystać ze&nbsp;standardowych skrótów klawiaturowych.</p>
        <h3 id="a11y-kontakt">Informacje zwrotne i&nbsp;dane kontaktowe</h3>
        <p>W przypadku problemów z&nbsp;dostępnością strony internetowej <a href="https://kodigo.ii.uph.edu.pl/">kodigo.ii.uph.edu.pl</a> prosimy o&nbsp;kontakt z &nbsp;<strong><span id="a11y-osoba">Marcinem Stępniakiem</span></strong>, e-mail: <strong><span id="a11y-email">marcin.stepniak@uph.edu.pl</span></strong>, tel.:&nbsp;<strong><span id="a11y-telefon">+48 25 643 11 54</span></strong>. Tą samą drogą można składać wnioski o&nbsp;udostępnienie informacji niedostępnej oraz&nbsp;składać skargi na&nbsp;brak zapewnienia dostępności.</p>
        <p><span id="a11y-procedura">Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji za pomocą alternatywnego sposobu dostępu, np. przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez audiodeskrypcji itp. Żądanie powinno zawierać </span></p>
        <ul>
          <li>dane osoby zgłaszającej żądanie,</li>
          <li>wskazanie, o którą stronę internetową lub aplikację mobilną chodzi,</li>
          <li>sposób kontaktu zwrotnego.</li>
        </ul>
        <p>Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny dla niej sposób przedstawienia tej informacji.<br />
          Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie później niż w ciągu 7 dni od dnia wystąpienia z żądaniem.<br />
          Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie poinformuje wnoszącego żądanie o tym, kiedy realizacja żądania będzie możliwa, przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem.<br />
          Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, podmiot publiczny może zaproponować alternatywny sposób dostępu do informacji.</p>
        <p>Za niedotrzymanie terminów oraz na odmowę realizacji żądania zapewnienia dostępności lub alternatywnego sposobu dostępu do informacji, wnoszący żądanie może złożyć skargę do pełnomocnika Rektora – koordynatora ds. dostępności jako osoby nadzorującej na adres:</p>
        <p><strong>Dr hab. Wiesława Barszczewska, prof. uczelni</strong> – pełnomocnik Rektora – koordynator ds. dostępności<br />
          Wydział Nauk Ścisłych i Przyrodniczych<br />
          Instytut Nauk Chemicznych<br />
          ul. 3 Maja 54<br />
          08-110 Siedlce<br />
          tel. 25 643 10 45<br />
          e-mail:&nbsp;<a href="mailto:wieslawa.barszczewska@uph.edu.pl">wieslawa.barszczewska@uph.edu.pl</a></p>
        <p>Po wyczerpaniu wskazanej wyżej procedury skargę można także złożyć do <a href="https://www.rpo.gov.pl/"><strong>Rzecznika Praw Obywatelskich</strong></a>.</p>
        <h2 id="a11y-architektura">Dostępność architektoniczna&nbsp;</h2>
        <ul>
          <li><a href="https://www.uph.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_wnsp.docx">Deklaracja dostępności budynku Instytutu Informatyki przy ul. 3 Maja 54.</a></li>
          <li><a href="https://www.uph.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_architektoniczna_ii.docx">Deklaracja dostępności budynku Instytutu Informatyki przy ul. Sienkiewicza 51.</a></li>
        </ul>
        <h2>Informacja o dostępności tłumacza języka migowego</h2>
        <p>Dostępni są tłumacze polskiego języka migowego (PJM):</p>
        <ul>
          <li><a href="https://www.uph.edu.pl/images/dostepnosc_architektoniczna/dostepnosc_tlumacza_jezyka_migowego.docx">Pobierz dane kontaktowe tłumaczy języka migowego</a></li>
        </ul>
        <h3>Informacje uzupełniające</h3>
        <p>Aktualnie trwają prace nad konwersją treści na&nbsp;stronie internetowej, aby były dostępne cyfrowo i&nbsp;spełniały wymagania WCAG na&nbsp;poziomie AA.</p>
        <p>Ponadto&nbsp;Uniwersytet Przyrodniczo-Humanistyczny w Siedlcach realizuje dwa projekty&nbsp;<strong>dofinansowane z Funduszy Europejskich:</strong></p>
        <ul>
          <li><a href="https://uniwersytetmaxi.uph.edu.pl/">„UPH w Siedlcach – Uniwersytet MAXI”</a>,</li>
          <li><a href="https://doswiadcztegosam.uph.edu.pl/">„Doświadcz tego sam”,</a></li>
        </ul>
        <p>które służą podniesieniu dostępności Uniwersytetu w aspekcie potrzeb osób z niepełnosprawnościami poprzez wypracowanie, przetestowanie i wdrożenie innowacyjnych rozwiązań inkluzyjnych oraz likwidację barier architektonicznych.</p>
    </div>
  )
}

export default AccessibilityStatement