import {useEffect,useContext,useState} from 'react'
import UserContext from '../../../context/UserContext'
import jwtAxios from '../../../utils/jwtAxios'
import { useParams,useNavigate } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import {FiDownload,FiSave} from 'react-icons/fi'
import { saveAs } from 'file-saver'


const Punctation = ()=>{
    const context = useContext(UserContext)
    const [maxPoints,setMaxPoints] = useState(0)
    const [assignments,setAssignments] = useState([])
    const [userAndPointsArr,setUserAndPointsArr] = useState([])
    const {contestId} = useParams()
    const navigate = useNavigate()

    useEffect(()=>{
        jwtAxios.get(`/api/Punctation/contestId/${contestId}`,{context:context})
        .then(res =>{ 
            setMaxPoints(res.data.maxPoints)
            setAssignments(res.data.assignments)
            setUserAndPointsArr(res.data.userAndPoints)
        })
        .catch(err=>console.log(err))
    },[])

    const updateUserAndPoints = (points,assignmentId,userAndPoints) =>{
       const new_arr = [...userAndPointsArr] //płytkie kopiowanie
       const new_userAndPoints = new_arr.find(up=>up.userId === userAndPoints.userId)
       const puncatation = new_userAndPoints.punctations.find(p=>p.assignmentId === assignmentId)//referencja, ten sam obiekt

       if(puncatation === undefined){
        new_userAndPoints.punctations.push({pointsObtained: points,assignmentId:assignmentId})
       }

       if(puncatation !== undefined){
        puncatation.pointsObtained = points
       }
       console.log(new_arr[0].answer === userAndPointsArr[0].answer)
       setUserAndPointsArr(new_arr)
    }

    return(
        <>
        <List userAndPointsArr={userAndPointsArr} assignments={assignments} maxPoints={maxPoints} updateUserAndPoints={updateUserAndPoints}/>
        <button onClick={() => navigate(`/adminPanel/contest/${contestId}`)} className="custom-button back-button"><FiSave size={15}/>Zapisz</button>
        </>
    )
}

const List = ({userAndPointsArr,assignments,maxPoints,updateUserAndPoints}) =>{

    const assignmentsRender = ()=>{
        return assignments.map((a,i)=><th key={i}>Zad {i+1}</th>)
    }

    if(userAndPointsArr.length !== 0){
        return (
            <Table responsive size="sm" style={{marginTop: "20px"}}>
            <thead style={{background: "#333333", color:"white"}}>
                <tr>
                <th style={{ minWidth: "200px"}}>Nazwisko i Imie</th>
                <th>Email</th>
                <th >Plik</th>
                {assignmentsRender()}
                <th><span style={{float: "right", marginRight: 10}}>Max Punktów</span></th>
                </tr>
            </thead>
            <tbody>
                {userAndPointsArr.map((item,i) => <Item userAndPoints={item} maxPoints={maxPoints} assignments={assignments} updateUserAndPoints={updateUserAndPoints}  key={i}/>)}
            </tbody>
            </Table>
    )
   } 
   
   return(
       <div>
           Brak Danych.
       </div>
   )
}


const Item = ({userAndPoints,maxPoints,assignments,updateUserAndPoints}) =>{

    const context = useContext(UserContext)
    const handleChange=(event,assignment,userAndPoints)=>{
        updateUserAndPoints(event.target.value,assignment.id,userAndPoints)
        sendPuncatation(event,assignment,userAndPoints)
    }

    const sendPuncatation=(event,assignment,userAndPoints)=>{
        const new_puncatation = {
            idAssignment: assignment.id,
            idAnswer: userAndPoints.answer.id,
            obtainedPoints: event.target.value
        }
      jwtAxios.post(`/api/Punctation`,new_puncatation,{context:context})
      .then(res=>res)
      .catch(err=>console.log(err)) 
    }

    const assignmentsRender = ()=>{
        const arrInputs = Array(assignments.length).fill(<></>);
        return arrInputs.map((item,index)=> {
            const points = userAndPoints.punctations.find(p=>p.assignmentId === assignments[index].id)?.pointsObtained ?? 0
            return(<td style={{ minWidth: "100px"}}  key={index}> <input aria-label={`Zad-${index+1}`} type='number' value={points}  onChange={e=>{handleChange(e,assignments[index],userAndPoints)}} style={{ width: "50px"}}/> / {assignments[index].points} </td>)
        })
    }

    function str2bytes (str) {
        var bytes = new Uint8Array(str.length);
        for (var i=0; i<str.length; i++) {
            bytes[i] = str.charCodeAt(i);
        }
        return bytes;
    }

    const onButtonClickDownload = (userAndPoints) => {
        jwtAxios.get(`/api/Answers/file/${userAndPoints.answer.id}`,{context:context, responseType: 'blob'})
        .then(response => {
                const filename = response.headers['content-disposition'].split('filename=')[1];
                const filename_final = filename.split(";")[0];
                var blob = new Blob([response.data], {type: "application/octet-stream"});
                saveAs(blob, filename_final); 
                })
    }

    return (
            <tr>
                <td>{userAndPoints.surname} {userAndPoints.name}</td>
                <td>{userAndPoints.email}</td>
                <td><button className='custom-button ' onClick={()=>onButtonClickDownload(userAndPoints)} aria-label="download" style={{padding: 2, width: "80%"}}><FiDownload/></button></td>
                {assignmentsRender()}
                <td><span style={{ float: "right", marginRight: 50}}>{maxPoints}</span></td>
            </tr>
    )
}
export default Punctation