import React from 'react'
import CookieConsent from 'react-cookie-consent'
import {BiCookie} from 'react-icons/bi'
import { Link } from 'react-router-dom';

const Cookies = () => {
  return (
    <div>
        <CookieConsent 
            //debug={true}
            style={{ "z-index": "1001"}}
            buttonWrapperClasses={"cookies-button-container"}
            buttonClasses={"cookies-button"}
            buttonText={"Akceptuj wszystkie"}
            expires={14}
            cookieName="AcceptCookie"
        >
            <BiCookie size={40}/> <span style={{ color: "#2187a0", fontWeight: 700}}>Kodigo</span> Cookies 
            <div style={{ padding: 10, marginLeft: 10}}>
                <h1 style={{ fontSize: 25}} >Twoja prywatność.</h1>
                Ta strona używa plików cookies, aby zapewnić lepszą wygodę użytkowania. 
                Korzystając z tej strony, wyrażasz zgodę na używanie przez nas wszystkich plików cookie zgodnie z warunkami zapisanymi w <Link to="regulamin" style={{color: "#df9705"}}>regulaminie</Link>.
            </div>
        </CookieConsent>
    </div>
  )
}

export default Cookies