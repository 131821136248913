import React, {useState,useEffect,useContext} from 'react'
import parser from 'html-react-parser';
import axios from 'axios'
import UserContext from '../context/UserContext';
import { Link } from 'react-router-dom';

const Statue = () => {
  const [regulation,setRegulation] = useState({})
  const {userStatus} = useContext(UserContext)

  useEffect(()=>{
    axios.get(`/api/WebsiteRegulations`)
    .then(res=>setRegulation(res.data))
    .catch(err=>setRegulation({}))
  },[])

  return (
    <div className="container" style={{marginTop: 20}}>
        {Object.keys(regulation).length === 0?
        'Brak Regulaminu'
        :
        parser(regulation.content)}
        <br />
        {userStatus.isAdmin?<><hr /><Link to={`/regulamin/createOrEdit`}><button variant="info" className="custom-button" style={{width: "150px", marginTop: "20px"}} >Edytuj Regulamin</button></Link></>:null }
    </div>
  )
}

export default Statue