import axios from 'axios';
import React, { useState }  from 'react'
import { Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Restore = () => {

    const navigate = useNavigate();
    const [emailForRecover, setEmailForRecover] = useState("")

    const handleInputChange = (e) => {
        setEmailForRecover(e.target.value);
    }
    
    const changePassword = (e) => {
        e.preventDefault();
        axios.get(`api/Authenticate/restorePassword/?email=${emailForRecover}`)
        .then(_ => {
            navigate("/");
        })
        .catch(err => {
            console.log(err);
        })
    }

  return (
    <Container className="form-container register-form">
            <h2>Odzyskaj hasło.</h2>
            <p>Podaj email aby odsykać hasło.</p>
            <hr />
            <Form>
                <Form.Group controlId="form.Password">
                    <Row>
                        <Form.Label style={{ width: "10%"}}>Email</Form.Label>
                        <Form.Control style={{width: "90%"}} type="email" placeholder="email" name="emailForRecover" value={emailForRecover} onChange={handleInputChange} ></Form.Control>
                    </Row>
                </Form.Group>
                <div>
                   <button className="custom-button" onClick={changePassword}>Odzyskaj hasło</button>
                </div>
            </Form>
        </Container>
  )
}

export default Restore