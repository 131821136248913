import React, { useContext } from 'react'
import UserContext from "./context/UserContext";
import CircleLoader from "react-spinners/CircleLoader";

const Loader = () => {
  const context = useContext(UserContext)
  
  return (
    <div class="d-flex justify-content-center">
        <CircleLoader color="#36d7b7" loading={context.loading} size={150} />
    </div>
  )
}

export default Loader