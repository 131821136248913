import {useContext, useReducer, useState} from "react"
import { Form, Container, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import jwtAxios from "../../../utils/jwtAxios";
import UserContext from "../../../context/UserContext";
import reducer,{ACTIONS} from "../../../reducer/ContestReducer";

import { imageUploadCallback } from '../../../utils/richTextEditorImages';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftjsToHtml from "draftjs-to-html";

const initContest = {
  contestName: "",
  description: "",
  applicationOpenDate: "",
  applicationEndDate: "",
  contestStartDate: "",
  localization: "",
  participatesLimit: 0,
  allowedAnswers: false,
  visibleAssignment: false,
  leaderboardVisible: false,
  leaderboardDescription:"",
  status: "Planned",
  contestRegulations:""
}

export default function ConstestCreate(){
    const [contest,dispatch] = useReducer(reducer,initContest)
    const context = useContext(UserContext)
    const navigate = useNavigate()

    const [editorStateForDescription, setEditorStateForDescription] = useState(EditorState.createEmpty())
    const [editorStateForLeaderboardDescription, setEditorStateForLeaderboardDescription] = useState(EditorState.createEmpty())
    const [editorStateForContestRegulations,setEditorStateForContestRegulations] = useState(EditorState.createEmpty())

    const handleInputChange = (e)=>{
        dispatch({
            type: ACTIONS.INPUT_VALUE,
            payload:{
                name: e.target.name,
                value: e.target.value
            }  
        })
    }

    const handleSubmit = (e) => {
        contest.description = draftjsToHtml(convertToRaw(editorStateForDescription.getCurrentContent()))
        contest.leaderboardDescription= draftjsToHtml(convertToRaw(editorStateForLeaderboardDescription.getCurrentContent()))
        contest.contestRegulations = draftjsToHtml(convertToRaw(editorStateForContestRegulations.getCurrentContent()))
        
        jwtAxios.post('/api/Contest/Add',contest,{context:context})
        .then(r=>navigate(`/adminPanel/contest/${r.data.id}`))
        .catch(err=>{
            if(err.response.status = 400){
                alert("Wysłano pusty obiekt")
            }else{
                console.log(err)
            }
        })
        e.preventDefault();
    }

    return (
        <Container className="form-container register-form">
        <h2>Dodaj Konkurs: {contest.id}</h2>
        <hr />
        <Form>
            <Form.Group controlId="form.contestName">
                <Row>
                    <Form.Label>Nazwa konkursu</Form.Label>
                    <Form.Control type="text" name="contestName" value={contest.contestName} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.description">
                    <Form.Label style={{fontWeight: 600}}>Opis:</Form.Label>
                    <div style={{border: "1px solid lightgray", padding: 10}}>
                    <Editor
                        editorState={editorStateForDescription}
                        editorStyle={{border: "1px solid", minHeight: "100px", overflow: "auto", maxHeight: "400px"}}
                        toolbar={{
                            image: {
                                uploadCallback: imageUploadCallback,
                                previewImage: true,
                            },
                        }}
                        onEditorStateChange={(editorState) => {
                            setEditorStateForDescription(editorState);
                        }}
                    />
                    </div>
            </Form.Group>
            <br />
            <Form.Group controlId="form.leaderboardDescription">
                    <Form.Label style={{fontWeight: 600}}>Leaderboard Opis:</Form.Label>
                    <div style={{border: "1px solid black", padding: 10}}>
                    <Editor
                        editorState={editorStateForLeaderboardDescription}
                        editorStyle={{border: "1px solid", minHeight: "100px", overflow: "auto", maxHeight: "400px"}}
                        toolbar={{
                            image: {
                                uploadCallback: imageUploadCallback,
                                previewImage: true,
                            },
                        }}
                        onEditorStateChange={(editorState) => {
                            setEditorStateForLeaderboardDescription(editorState);
                        }}
                    />
                    </div>
            </Form.Group>
            <Form.Group controlId="form.applicationOpenDate">
                <Row>
                    <Form.Label>Start Rejestracji</Form.Label>
                    <Form.Control type="date" name="applicationOpenDate" value={contest.applicationOpenDate} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.applicationEndDate">
                <Row>
                    <Form.Label>Koniec Rejstracji</Form.Label>
                    <Form.Control type="date" name="applicationEndDate" value={contest.applicationEndDate} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.contestStartDate">
                <Row>
                    <Form.Label>Dzień Konkursu</Form.Label>
                    <Form.Control type="datetime-local" name="contestStartDate" value={contest.contestStartDate} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.localization">
                <Row>
                    <Form.Label>Miejsce Konkursu</Form.Label>
                    <Form.Control type="text" name="localization" value={contest.localization} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>
            <Form.Group controlId="form.participatesLimit">
                <Row>
                    <Form.Label>Liczba uczestników</Form.Label>
                    <Form.Control type="number" name="participatesLimit" value={contest.participatesLimit} onChange={handleInputChange} ></Form.Control>
                </Row>
            </Form.Group>

            <Form.Group controlId="form.contestRegulations">
                    <Form.Label style={{fontWeight: 600}}>Regulamin Konkursu:</Form.Label>
                    <div style={{border: "1px solid black", padding: 10}}>
                    <Editor
                        editorState={editorStateForContestRegulations}
                        editorStyle={{border: "1px solid", minHeight: "100px", overflow: "auto", maxHeight: "400px"}}
                        toolbar={{
                            image: {
                                uploadCallback: imageUploadCallback,
                                previewImage: true,
                            },
                        }}
                        onEditorStateChange={(editorState) => {
                            setEditorStateForContestRegulations(editorState);
                        }}
                    />
                    </div>
            </Form.Group>
            <br />
            <div>
               <button className="custom-button" onClick={handleSubmit}>Zapisz</button>
            </div>
        </Form>
    </Container>)
}