import React, {useEffect,useState} from "react"
import axios from "axios"
import {useParams,useNavigate} from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import parser from 'html-react-parser';

const ContestRegulation = () => {
    const {id}  = useParams()
    const navigate = useNavigate()
    const [regulation,setRegulation] = useState('')

    useEffect(()=>{
        axios.get('/api/Contest/'+id)
        .then(({data})=>{
            const tempRegulation = data.contestRegulations ?? '<p>Brak Regulaminu</p>'
            setRegulation(tempRegulation)
        })
        .catch(err=>setRegulation(''))
      },[])

    return(
    <div className="container" style={{marginTop: 20}}>
    {parser(regulation)}
    <br />    
    <button  className="custom-button home-button" style={{width: "150px", marginTop: "20px"}} onClick={()=>navigate(-1)}><AiOutlineArrowLeft size={15}/>Wróć</button>
    </div>)

}

export default ContestRegulation