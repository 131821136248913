import React, {useEffect, useState} from 'react';
import {ACTIONS} from '../reducer/NotificationReducer';
import { AiFillCheckCircle, AiFillWarning, AiOutlineClose } from 'react-icons/ai';


const Notification = (props) => {

    const [exit,setExit] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            handleCloseNotification();
        }, 5000)
    },[])

    const handleCloseNotification = () => {
        setExit(true);
        setTimeout(() => {
            props.dispatch({
                type: ACTIONS.REMOVE_NOTIFICATION,
                payload: {
                    id: props.id
                }
            })
        }, 400)
    }

  return (
    <div 
    className={`notification-item
    ${props.type === "SUCCESS" ? "success" : "error"} 
    ${exit ? "exit" : ""}`
    }>
        
        {props.type === "SUCCESS" ? <><div className='notification-icon'><AiFillCheckCircle size={20}/></div><div className='notification-text'><p style={{ color: "#555555"}}>{props.msg}</p><p style={{paddingTop:0, marginTop:0, fontSize:10}}>Sukces</p></div></> : ""}
        {props.type === "ERROR" ? <><div className='notification-icon'><AiFillWarning size={20}/></div><div className='notification-text'><p style={{ color: "#555555"}}>{props.msg}</p><p style={{paddingTop:0, marginTop:0, fontSize:10}}>Bład</p></div></> : ""}
        <div className='notification-close'><AiOutlineClose fontWeight={900} size={18} onClick={handleCloseNotification}/></div>
    </div>
  )
}

export default Notification