import React,{useEffect,useState,useContext} from "react"
import jwtAxios from '../../../utils/jwtAxios'
import UserContext from '../../../context/UserContext'
import {imageUploadCallback, loadDataFromHtmlToDraft} from '../../../utils/richTextEditorImages';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftjsToHtml from "draftjs-to-html"
import { Form, Container} from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import { AiOutlineArrowLeft } from 'react-icons/ai';

const RegulationEditor = () =>{
    const [editorRegulation, setEditorRegulation] = useState(EditorState.createEmpty())
    const [isContent,setIsContent] = useState(false)
    const context = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(()=>{
        jwtAxios.get(`/api/WebsiteRegulations`,{context: context})
        .then(res=>{
            loadDataFromHtmlToDraft(res.data.content,setEditorRegulation);
            setIsContent(true)
        })
        .catch(err=>{
            if(err.response.status === 404){
                setIsContent(false)
            }else{
                alert('Coś poszło nie tak')
            }
        })

    },[])

    
    const handleSubmit = (e) => {
        e.preventDefault()
        const regulation = draftjsToHtml(convertToRaw(editorRegulation.getCurrentContent()))
        if(!isContent){
            jwtAxios.post(`/api/WebsiteRegulations`,{content: regulation},{context: context})
            .then(res=>navigate('/regulamin'))
            .catch(err=>{
                if(err.response.status === 409){
                    alert("Regulamin już istnieje")
                }else{
                    alert('Coś poszło nie tak')
                }
            })
        }else{
            jwtAxios.put(`/api/WebsiteRegulations`,{content: regulation},{context: context})
            .then(res=>navigate('/regulamin'))
            .catch(err=>{
                if(err.response.status === 404){
                    alert("Nie można zaktualizaować")
                }else{
                    alert('Coś poszło nie tak')
                }
            })
        }
    }

    return(
            <Container>
            <Form style={{marginTop: "20px"}}>
                <Form.Group controlId="form.description">
                        <Form.Label style={{fontWeight: 600}} className="h1--custom">Regulamin:</Form.Label>
                        <div style={{border: "1px solid lightgray", padding: 10}}>
                        <Editor
                            editorState={editorRegulation}
                            editorStyle={{border: "1px solid", minHeight: "500px", overflow: "auto"}}
                            toolbar={{
                                image: {
                                    uploadCallback: imageUploadCallback,
                                    previewImage: true,
                                },
                            }}
                            onEditorStateChange={(editorState) => {
                                setEditorRegulation(editorState);
                            }}
                        />
                        </div>
                </Form.Group>
                <br />
                <div>
                    <button className="custom-button"  style={{width: "150px"}} onClick={handleSubmit}>Zapisz</button>
                </div>            
            </Form>
            <hr />
            <Link to={`/regulamin`}><button  className="custom-button back-button" style={{width: "150px", marginTop: "20px"}} ><AiOutlineArrowLeft size={15}/>Wróć</button></Link>
        </Container>)
}

export default RegulationEditor