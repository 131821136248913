import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

//---------Handling Files/Images--------------
const getFileBase64 = (file, callback) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
    // TODO: catch an error
    reader.onerror = error => {};
};
  
const imageUploadCallback = file => new Promise(
    (resolve, reject) => getFileBase64(
      file,
      data => resolve({ data: { link: data } })
    )
)

const loadDataFromHtmlToDraft = (data, callback) => {
            const blocksFromHtml = htmlToDraft(data);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            callback(editorState);
}

export {imageUploadCallback, loadDataFromHtmlToDraft};