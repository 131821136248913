import './App.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import { useContext } from "react";
import Navbar from './Navbar';
import Footer from './Footer';
import Loader from './Loader';
import Kontakt from './components/Kontakt';
import NotFound from './components/NotFound';
import Home from './components/Home';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import { UserContextProvider } from './context/UserContext';
import { NotificationContextProvider } from './context/NotificationContext';
import AuthRoute from './routes/AuthRoute';
import Profile from './components/Profile';
import ContestDetails from './components/contest/ContestDetails';
import AdminContestList from './components/adminpanel/contest/AdminConstestList'
import AdminContestDetails from './components/adminpanel/contest/AdminContestDetails'
import ConstestEdit from './components/adminpanel/contest/Edit'
import ConstestCreate from './components/adminpanel/contest/Create';
import AdminPanel from './components/adminpanel/AdminPanel';
import CreateAssignment from './components/adminpanel/assignment/CreateAssignment';
import UserList from './components/adminpanel/users/UserList';
import UserDetails from './components/adminpanel/users/Details';
import UserEdit from './components/adminpanel/users/Edit';
import EditAssignment from './components/adminpanel/assignment/EditAssignment';
import ContestList from './components/contest/ContestList';
import Punctation from './components/adminpanel/punctation/Punctation';
import Restore from './components/auth/Restore';
import Change from './components/auth/Change';
import Cookies from './Cookies';
import Statue from './components/Statue';
import AccessibilityStatement from './components/AccessibilityStatement';
import InstitutionList from './components/adminpanel/institutions/InstitutionList';
import RegulationEditor from './components/adminpanel/websiteRegulations/CreateOrEdit'
import ContestRegulation from './components/contest/contestRegulation/ContestRegulation';
import InstitutionEdit from './components/adminpanel/institutions/Edit';

function App() {

  return (
    <BrowserRouter>
    <UserContextProvider>
    <NotificationContextProvider>
      <div className='allButFooter'>
        <Navbar />
      
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="kontakt" element={<Kontakt />}></Route>
          <Route path="deklaracja" element={<AccessibilityStatement />}></Route>
          <Route path="regulamin" element={ <Statue />}></Route>
          <Route path='regulamin/createOrEdit' element={<AuthRoute role={"Admin"}><RegulationEditor /></AuthRoute>}></Route>
          <Route path="login" element={<Login /> }></Route>
          <Route path="register" element={<Register /> }></Route>
          <Route path="contests" element={<ContestList />}></Route>
          <Route path="contests/regulation/:id" element={<ContestRegulation />}></Route>
          <Route path="contest/:id" element={<ContestDetails />}></Route>
          <Route path="restore" element={ <Restore />}></Route>
          <Route path="changePassword" element={ <Change /> }></Route>


          <Route path="adminPanel" element={
          <AuthRoute role={"Admin"}>
              <AdminPanel/>
          </ AuthRoute>
          }>
            <Route path="contests" element={<AdminContestList />}/> 
            <Route path="contest/:id" element={<AdminContestDetails />}/>
            <Route path="contest/edit/:id" element={<ConstestEdit />}/>
            <Route path="contest/add/" element={<ConstestCreate />}/>
            <Route path="assignment/add/:contestId" element={<CreateAssignment />}/>
            <Route path="assignment/edit/:contestId" element={<EditAssignment />}/>
            <Route path="users" element={<UserList />}></Route>
            <Route path="institutions" element={<InstitutionList/>}></Route>
            <Route path="user/:id" element={<UserDetails />}></Route>
            <Route path="user/edit/:id" element= {<UserEdit />}></Route>
            <Route path="points/edit/:contestId" element= {<Punctation />}></Route>
            <Route path="institution/edit/:institutionId" element= {<InstitutionEdit />}></Route>
          </Route>

          <Route
            path="profile"
            element={
              <AuthRoute>
                <Profile />
              </AuthRoute>
            }
          />
          
          <Route path="403" element={<Forbidden /> }></Route>
          <Route path="500" element={<ServerError />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>

        <Loader />

      </div>    

        <Footer />
        <Cookies/>
        
    </NotificationContextProvider>
    </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;


function Forbidden(){
  return <div>Forbidden</div>
}

function ServerError(){
  return <div>Server Error! 500.</div>
}