import React, { useContext } from 'react';
import axios from 'axios';
import { Form, Container, Row } from 'react-bootstrap';
import {useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationContext,{NotificationType} from '../../context/NotificationContext';
import PasswordChecklist from "react-password-checklist"
 
const Register = () => {
  
    const notification = useContext(NotificationContext);

    const navigate = useNavigate();

    const [institutionList,setInstitutionList] = useState([])

   const [registerValues,setRegisterValues] = useState({
        email: "",
        name: "",
        surname: "",
        password: "",
        confirmpassword: "",
        institutionName: "",
        isValidPassword: false
   }) 

   const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRegisterValues(pervValue => {
            return {...pervValue, [name]: value }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(registerValues.isValidPassword){
            register();         
        }else{
            notification('Podano nieprawidłowe dane w formularzu.', NotificationType.ERROR);
        }
    } 

    const register = () => {
        const userRegister = {
            username: registerValues.email,
            email: registerValues.email,
            password: registerValues.password,
            name: registerValues.name,
            surname: registerValues.surname,
            institutionName: registerValues.institutionName
        };

        axios.post('/api/Authenticate/register',userRegister,{headers:{'Content-Type':'application/json'}})
        .then(response => {
            notification('Zarejestrowano pomyślnie.', NotificationType.SUCCESS);
            navigate("/login")
        })
        .catch(err => alert('Wrong data')); //this is temporary error.
    }

    const handleChange = (e) => {
        if(e.target.value!==""){
        axios.get(`/api/Authenticate/institutionSearch?searchTerm=${e.target.value}`)
        .then(response => {
            setInstitutionList(response.data)
        })
        .catch(err => alert('Something went wrong.'));
        }

        setRegisterValues(pervValue => {
            return {...pervValue, institutionName: e.target.value}
        })
    }

    const onSearch = (searchTerm) => {
        setInstitutionList([])

        setRegisterValues(pervValue => {
            return {...pervValue, institutionName: searchTerm}
        })
    }

  return (
        <Container className="form-container register-form">
            <h2>Zarejestruj się.</h2>
            <p>Utwórz nowe konto.</p>
            <hr />
            <Form>
                <Form.Group controlId="form.Email">
                    <Row>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="email" value={registerValues.email} onChange={handleInputChange} ></Form.Control>
                    </Row>
                </Form.Group>
                <Form.Group controlId="form.Name">
                    <Row>
                        <Form.Label>Imie</Form.Label>
                        <Form.Control type="text" name="name" value={registerValues.name} onChange={handleInputChange} ></Form.Control>
                    </Row>
                </Form.Group>
                <Form.Group controlId="form.Surname">
                    <Row>
                        <Form.Label>Nazwisko</Form.Label>
                        <Form.Control type="text" name="surname" value={registerValues.surname} onChange={handleInputChange} ></Form.Control>
                    </Row>
                </Form.Group>  
                <Form.Group controlId="form.Password">
                    <Row>
                        <Form.Label>Hasło</Form.Label>
                        <Form.Control type="password" placeholder="hasło" name="password" value={registerValues.password} onChange={handleInputChange} ></Form.Control>
                    </Row>
                </Form.Group>
                <Form.Group controlId="form.ConfirmPassword">
                    <Row>
                        <Form.Label>Potwierdź Hasło</Form.Label>
                        <Form.Control type="password" placeholder="potwierdź hasło" name="confirmpassword" value={registerValues.confirmpassword} onChange={handleInputChange} ></Form.Control>
                    </Row>
                </Form.Group>
                <Form.Group controlId="form.PasswordChecklist">
                    <Row>
                        <PasswordChecklist
                            rules={["minLength","specialChar","number","lowercase","capital","match"]}
                            minLength={6}
                            value={registerValues.password}
                            valueAgain={registerValues.confirmpassword}
                            onChange={(isValid) => {registerValues.isValidPassword=isValid}}
                            messages={{
                                minLength: "Hasło zawiera przynajmniej 6 znaków.",
                                specialChar: "Hasło zawiera znaki specjalne.",
                                number: "Hasło zawiera liczbę.",
                                lowercase: "Hasło zawiera małą literę.",
                                capital: "Hasło zawiera wielką literę.",
                                match: "Hasła są zgodne.",
                            }}
                        />
                    </Row>
                </Form.Group>        
                <br />
                <Form.Group controlId="form.institutionName">
                    <Form.Label>Placówka</Form.Label>
                    <Form.Control type="text" value={registerValues.institutionName} name="institutionName" onChange={handleChange}></Form.Control> 
                    <div className="input-dropdown">
                        {
                        institutionList.map((item) => (
                        <div onClick={() => onSearch(item.institutionName)} className='dropdown-row' key={item.id}>
                            {item.institutionName}
                        </div>
                        ))
                        }
                    </div>
                </Form.Group>
                <br />
                <div>
                   <p>Dokonując rejestracji akceptujesz <a href='/regulamin'>Regulamin konkursu Kodigo</a>.</p>
                   <button className="custom-button" onClick={handleSubmit}>Zarejestruj</button>
                </div>
            </Form>
        </Container>
  )
}

export default Register