import React, { useEffect, useState, useContext } from 'react';
import jwtAxios from '../../utils/jwtAxios';
import UserContext from '../../context/UserContext';
import { Table, Badge, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

const ContestList = () => {
    const[currentPage,setCurrentPage]=useState(1)
    const[page,setPage] = useState({})
    const context = useContext(UserContext)

    useEffect(() => {
        context.setLoading(true);
        jwtAxios.get(`/api/Contest/WithoutPlanned?page=${currentPage}`,{context:context})
        .then(r=>{
            setPage(r.data)
            context.setLoading(false);
        }).catch(err=>{
            context.setLoading(false);
            if(err.response.status === 404){
                setPage({})
            }else{
                console.log(err)
            }
        })
    }, [currentPage])

    const changePage = ({selected}) => {
        setCurrentPage(selected+1);
    }

    const listOfContests = page.content ? 
    page.content.map(contest => {
       return(<ItemContest item={contest} key={contest.id} />)
    }) 
    : 
    [];

  return (
    <div className="container-custom">
    <div className="contest-list" style={{marginTop: 20}}>
        <h1 className="h1--custom">Edycje konkursów</h1>
            <Table borderless hover size="sm" className="contest-table">
                <thead>
                    <tr>
                    <th>Status</th>
                    <th>Konkurs</th>
                    <th>Start konkursu</th>
                    </tr>
                </thead>
                <tbody>
                    {listOfContests}
                </tbody>
            </Table>
            <div>
            <ReactPaginate 
                    previousLabel={<AiOutlineLeft size={20} />}
                    nextLabel={<AiOutlineRight size={20} />}
                    pageCount={page.totalPageCount}
                    onPageChange={changePage}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    containerClassName={"pagination-buttons"}
                    previousLinkClassName={"pagination-pervious"}
                    nextLinkClassName={"pagination-next"}
                    disabledClassName={"pagination-disabled"}
                    activeClassName={"pagination-active"}
                />
            </div>
    </div>
    </div>
  )
}

const ItemContest = ({item}) => {
    var startDate = item.contestStartDate.split('T');
    var hour = startDate[1].split(":");
    return(
        <tr className="custom-list-item">
            {item.status==="Active" ? <td><Badge bg="success">Aktywny</Badge></td> : null}
            {item.status==="Planned" ? <td><Badge bg="secondary">Zaplanowany</Badge></td> : null}
            {item.status==="Ended" ? <td><Badge bg="danger">Zakończony</Badge></td> : null}
            <td>
                <Link to={`/contest/${item.id}`}>{item.contestName}</Link>
                <p style={{fontSize: 10, color: "#999999"}}>Konkurs</p>
            </td>
            <td>
                {startDate[0]} | {hour[0]}:{hour[1]} godz. 
                <p style={{fontSize: 10, color: "#999999"}}>Start</p>
            </td>
        </tr>
    )
}


export default ContestList