import React from 'react'

const TextLogo = () => {
  return (
      <span>
          <span style={{color: 'white'}}>{'{'}</span><span style={{color: 'red'}}>Kod</span><span style={{color: 'white'}}>i</span><span style={{color: 'red'}}>go</span>
          <span style={{color: 'white'}}>{';}'}</span></span>
  )
}

export default TextLogo