import React from 'react'

const Kontakt = () => {
  return (
    <div className="container" style={{marginTop: 20}}>
        <h1 className="h1--custom">Kontakt</h1>
        <p>
            <br />
            Instytut Informatyki UPH <br/>
            ul. 3 Maja 54 <br />
            08-110 Siedlce
        </p>
        <br />
        <address>
            <strong>Zgłaszanie problemów:</strong> <a href="mailto:kodigo@strefa.ii.uph.edu.pl" style={{color: "#CA8012", fontWeight: 600}}>kodigo@strefa.ii.uph.edu.pl</a>
        </address>
    </div>
  )
}

export default Kontakt