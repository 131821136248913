const ACTIONS ={
    ADD_NOTIFICATION: "add",
    REMOVE_NOTIFICATION: "remove"
}

const reducer = (state,action) => {
    switch(action.type){
        case ACTIONS.ADD_NOTIFICATION:
            return [...state, {...action.payload}];
        case ACTIONS.REMOVE_NOTIFICATION:
            return state.filter(item => item.id !== action.payload.id);
        default: 
            return state;
    }
}

export default reducer
export {ACTIONS}