import React, { useState, useEffect, useContext,useRef } from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import UserContext from '../../context/UserContext';
import jwtAxios from '../../utils/jwtAxios';
import NotificationContext, {NotificationType} from '../../context/NotificationContext'

const AnswerModal = ({isRegistered,contest}) =>{
    const [show, setShow] = useState(false)
    const [answer,setAnswer] = useState({})
    const fileRef = useRef(null)
    const context = useContext(UserContext)
    const notification = useContext(NotificationContext)
  
    useEffect(()=>{
      if(context.userStatus.isLogged && contest.id ){
      jwtAxios.get(`/api/Answers/answerCheck?contestId=${contest.id}&userId=${context.user.userid}`,{context:context})
       .then(res=>{
         setAnswer(res.data)
       })
       .catch(err=>{
         if(err.response.status === 404){
          setAnswer({})
         }else if(err.response.status === 403){
          notification("Nie masz uprawnień",NotificationType.ERROR)
         }
         else{
          notification("Coś poszło nie tak",NotificationType.ERROR)
         }
       })
     }
    },[contest.id,context.userStatus.isLogged])
  
    const sendFile = () =>{
      if(fileRef.current.files.length < 1){
        notification("Musi być przynajmniej jedno zdjecie",NotificationType.ERROR)
        return;
      }
  
      const formData = new FormData();
      const fileName =context.user.username+'-'+fileRef.current.files[0].name
      formData.append('file',fileRef.current.files[0],fileName);
      
      jwtAxios.post(`/api/Answers?contestId=${contest.id}`,formData, {headers:{'Content-Type':'multipart/form-data'},context:context})
      .then(res => {
        setAnswer(res.data)
        setShow(false)
        notification('Przesłano zadania',NotificationType.SUCCESS)
      })
      .catch(err=>{
        if(err.response.status === 404){
           notification('Konkurs o tym Id nie istnieje',NotificationType.ERROR)
           console.log(err)
         }else if(err.response.status === 409){
           notification(err.response.data,NotificationType.ERROR)
           console.log(err)
         }
         else{
           notification('Cos poszło nie tak',NotificationType.ERROR)
           console.log(err)
         }
      });
    }

    const renderInfo = (isRegistered) =>{
      if(isRegistered && Object.keys(answer).length !== 0){
        return <><br/><i style={{ color: "rgb(59, 192, 59)"}}>Przesłano odpowiedź.</i></>
      }

      return <></>
    }
  
    return (
      <>
      {context.userStatus.isLogged && contest.allowedAnswers && isRegistered && contest.contestStatus === 'Active' && Object.keys(answer).length === 0? 
          <>
            <Button onClick={()=>setShow(true)}>Prześlij odpowiedzi</Button>
            <Modal show={show} onHide={()=>setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Prześlij odpowiedzi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
              <Form.Group controlId="form.contestName">
                      <Form.Label>Plik (.zip)</Form.Label>
                      <Form.Control type="file" name="contestName" ref={fileRef} accept='.zip'></Form.Control>
              </Form.Group>
            </Form>  
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>setShow(false)}>
                Zamknij
              </Button>
              <Button variant="primary" onClick={sendFile}>
                Wyślij plik
              </Button>
            </Modal.Footer>
          </Modal>
          </>: renderInfo(isRegistered)}
      </>
  )
  }

  export default AnswerModal