import { useEffect, useState, useContext } from "react"
import jwtAxios from '../../utils/jwtAxios'
import { Card } from "react-bootstrap"
import UserContext from '../../context/UserContext'
import parser from 'html-react-parser' 
import NotificationContext, {NotificationType} from '../../context/NotificationContext'

const AssignmentList = ({contestId}) =>{
    const [assignments,setAssignments] = useState([])
    const context = useContext(UserContext)
    const notification = useContext(NotificationContext)

    useEffect(()=>{
        jwtAxios.get(`/api/Assignment/contest/${contestId}`,{context:context})
        .then(res=>setAssignments(res.data))
        .catch(err => {
             if(err.response.status === 403){
                 setAssignments([])
             }
             else if (err.response.status === 404){
                notification(err.response.data,NotificationType.ERROR)
             }else{
                notification('Coś poszło nie tak',NotificationType.ERROR)
             }
        })
    },[])

    if(assignments.length === 0){
        return(null)
    }

    return(
        <div>
            {assignments.map((item,i)=><AssignmentItem item={item} key={i} index={i}/>)}
        </div>
    )
}

const AssignmentItem = ({item,index}) =>{
    return(
        <Card.Body style={{border: "inherit"}}>
            <Card.Title>Zadanie: {index+1} <small className="text-muted">Liczba Punktów: {item.points}</small></Card.Title>
            <div className="card-text">
            {parser(item.content)}
            </div>
        </Card.Body> 
        )
}

export default AssignmentList