import { Table } from 'react-bootstrap';

const Leaderboard = ({leaderboard}) =>{
    if(leaderboard.userAndPoints.length !== 0){
        return (
            <Table responsive size="sm">
            <thead style={{background: "#333333", color:"white"}}>
                <tr>
                <th>Miejsce</th>
                <th>Nazwisko i Imie</th>
                <th>Punkty</th>
                <th>Data Przesłania</th>
                </tr>
            </thead>
            <tbody>
                {leaderboard.userAndPoints.map((item,i) => <LeaderboardItem item={item} place={i} key={i}/>)}
            </tbody>
            </Table>
    )
   } 
   
   return(
       <div>
           Brak
       </div>
   )
}
const LeaderboardItem=({item,place})=>{
    return(
        <tr>
            <td>{place+1}</td>
            <td>{item.surname} {item.name}</td>
            <td>{item.totalPoints}</td>
            <td>{item.answer?.answerDate.replace('T',' ')}</td>
        </tr>
    )
}

export default Leaderboard