const ACTIONS ={
    LOAD: 'load',
    INPUT_VALUE:'inputValue',
    TOGGLE:'toggle'
}

const reducer = (state,action) =>{
    switch(action.type){
        case ACTIONS.LOAD:
            return {
                id: action.payload.id,
                email: action.payload.email,
                name: action.payload.name,
                surename: action.payload.surename,
                institutionName: action.payload.institutionName || "Brak",
                isConfirm: action.payload.isConfirm,
                created: action.payload.created
            };
        case ACTIONS.INPUT_VALUE:
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }; 
        case ACTIONS.TOGGLE :
            const temp = state[action.payload.name]
            return {
                ...state,
                [action.payload.name]: !temp
            };       
        default:
            return state   
    }
}

export default reducer
export {ACTIONS}