import { useEffect, useState, useContext } from "react"
import { useParams,Link } from "react-router-dom"
import jwtAxios from '../../../utils/jwtAxios'
import UserContext from '../../../context/UserContext'
import { Collapse, Card, Nav, ProgressBar } from "react-bootstrap"
import AssignmentList from '../../assignment/AssignmentList'
import parser from 'html-react-parser'
import { ImCheckmark, ImCross } from 'react-icons/im'
import { IoMdArrowDropdown } from 'react-icons/io'
import ParticipantsList from "./ParticipantsList"
import Leaderboard from "./Leaderboard"
import axios from "axios"

const STATUS = {
    PLANNED:"Planned",
    ACTIVE:"Active",
    ENDED:"Ended"
}

export default function AdminContestDetails(){
    const [contest, setContest] = useState({})
    const [participants, setParticipants] = useState([])
    const [detailsTab, setDetailsTab] = useState("zadania")
    const [expanded, setExpanded] = useState(false);
    const [leaderboard,setLeaderboard]= useState({})

    const context = useContext(UserContext)
    const {id} = useParams()

    useEffect(()=>{  
       fetchContest()
       fetchParticipants()
       fetchLeaderboard()   
    },[])

    const fetchContest = () => {
        context.setLoading(true);
        jwtAxios.get(`/api/Contest/${id}`,{context:context})
        .then(res=> { setContest(res.data); context.setLoading(false); })
        .catch(err=>{
            if(err.response.status === 404){
                console.log('Not Found')
                setContest({})
            }
            else{
                console.log(err)
            }
            context.setLoading(false);
        })
    }

    const fetchParticipants = () => {
        jwtAxios.get(`/api/ContestRegistration/AllContestRegistrations?page=1&idContest=${id}`,{context:context})
        .then(res => {
            setParticipants(res.data.content);
        })
        .catch(err => {
            if(err.response.status === 404){
                setParticipants([])
            }else{
                console.log(err);
            }
        })
    }

    const fetchLeaderboard = async () =>{ 

        jwtAxios.get(`/api/Punctation/leaderboard/${id}`,{context:context})
        .then(response=>setLeaderboard(response.data))
        .catch(async err=>{
            if(err.response && err.response.status === 409){
                try{
                    const response = await axios.post('/api/Authenticate/refresh',{headers:{'Content-Type':'application/json'},withCredentials:true})
                    context.setJWT(response.data.jwt)
                    axios.get(`/api/Punctation/leaderboard/${id}`,{
                        headers: {
                          Authorization: 'Bearer ' + response.data.jwt  
                        }
                       })
                     .then(response=>setLeaderboard(response.data))
                     .catch(err2=>console.log(err2))
                }catch(err2){
                    context.logout();
                }
            }
        })
    }

    const changeAssignmentVisibility = () => {
        jwtAxios.put(`/api/Contest/changeAssignmentVisibilty/${contest.id}`,{},{context:context})
        .then(res=>{
           if(res.data){
               fetchContest()
           }else{
               alert("something gone wrong")
           }
        })
        .catch(err=>{if(err.response.status === 404){
                setContest({})
            }else if (err.response.status === 400){
                console.log(err)
            }else{
                console.log(err)
            }
        })
    }

    const changeAllowAnswers = () => {
        jwtAxios.put(`/api/Contest/changeAllowAnswers/${contest.id}`,{},{context:context})
        .then(res=>{
           if(res.data){
               fetchContest()
           }else{
               alert("something gone wrong")
           }
        })
        .catch(err=>{if(err.response.status === 404){
                setContest({})
            }else if (err.response.status === 409){
                alert(err.response.data)
            }else{
                console.log(err)
            }
        })  
    }

    const openRegistartion = () => {
        jwtAxios.put(`/api/Contest/openRegistration/${contest.id}`,{},{context:context})
        .then(res=>{
           if(res.data){
               fetchContest()
           }else{
               alert("something gone wrong")
           }
        })
        .catch(err=>{if(err.response.status === 404){
                setContest({})
            }else if (err.response.status === 409){
                alert(err.response.data)
            }else{
                console.log(err)
            }
        })  
    }

    const closeRegistartion = () => {
        jwtAxios.put(`/api/Contest/endRegistration/${contest.id}`,{},{context:context})
        .then(res=>{
           if(res.data){
               fetchContest()
           }else{
               alert("something gone wrong")
           }
        })
        .catch(err=>{if(err.response.status === 404){
                setContest({})
            }else if (err.response.status === 409){
                alert(err.response.data)
            }else{
                console.log(err)
            }
        })
    }

    const setStatus = (status) => {
        jwtAxios.put(`/api/Contest/setStatus/${contest.id}?status=${status}`,{},{context:context})
        .then(res=>{
            if(res.data){
                fetchContest()
            }else{
                alert("something went wrong")
            }
        })
        .catch(err=>{
            if(err.response.status === 404){
                setContest({})
            }else if (err.response.status === 409){
                alert(err.response.data)
            }else{
                console.log(err)
            }
    }) 
    }

    const changeTab = (tab) => {
        setDetailsTab(tab);
    }

    const removeRegistration = (userId,registerId) =>{
        jwtAxios.delete(`/api/ContestRegistration/remove?userId=${userId}&registerId=${registerId}`,{context:context})
        .then(res=>{
                //fetch all registration again
                fetchParticipants()
        })
        .catch(err=>console.log(err))
    }

    const changeLeaderboardVisibilty=()=>{
        jwtAxios.put(`/api/Contest/changeLeaderboardVisibilty/${contest.id}`,{},{context:context})
        .then(res=>{
           if(res.data){
               fetchContest()
           }else{
               alert("something gone wrong")
           }
        })
        .catch(err=>{if(err.response.status === 404){
                setContest({})
            }else if (err.response.status === 400){
                alert(err.response.data)
            }else{
                console.log(err)
            }
        })  
    }

    const rednerContent = () => {
        if(detailsTab === "zadania"){
            return(
                <>
                <Card.Body style={{border: "inherit"}} role="tabpanel" aria-labelledby="tab-zadania">
                    {contest.assignmentsCount === 0 ? 
                    <div>
                    <Card.Text>
                    Brak zadań.
                    </Card.Text>
                    <Link to={`/adminPanel/assignment/add/${contest.id}`}><button variant="info" className="custom-button" style={{width: "150px"}}>Dodaj Zadania</button></Link> 
                    </div>
                    :
                    context.userStatus.isAdmin && contest.status !== 'Ended'?<Link to={`/adminPanel/assignment/edit/${contest.id}`}><button variant="info" className="custom-button" style={{width: "150px"}} >Edytuj Zadania</button></Link> : null}
                </Card.Body>
                {contest.assignmentsCount !== 0 ? 
                    <AssignmentList contestId={id}/>
                    :
                ""}
                </>
            )
        }else if (detailsTab === 'uczestnicy'){
            return(
                <>
                  {contest.status==='Ended'?
                    <Card.Body style={{border: "inherit"}} role="tabpanel" aria-labelledby="tab-uczestnicy">
                      <Link to={`/adminPanel/points/edit/${contest.id}`}><button className="custom-button" style={{width: "150px"}}>Oceń Prace</button></Link>
                    </Card.Body>:null}  
                <Card.Body>
                {<ParticipantsList participants={participants} removeRegistration={removeRegistration} contestStatus={contest.status}/>}
                </Card.Body>
                </>
            )
        }else {
            return(
                <>
                <Card.Body role="tabpanel" aria-labelledby="tab-tablicawynikow">
                    <Leaderboard leaderboard={leaderboard}/>
                </Card.Body>
                </>
            )
        }
     }

     const renderRegistrationButtons = () => {
        var applicationStartDate = new Date(contest.applicationOpenDate);
        var applicationEndDate = new Date(contest.applicationEndDate);
        var today = new Date();
        if(today < applicationStartDate){
            return(<>Wyłączona <button onClick={openRegistartion} className="admin-option-button">Rozpocznij</button></>)
        }
        if(applicationStartDate < today && today < applicationEndDate){
            return(<>Włączona <button onClick={closeRegistartion} className="admin-option-button" style={{backgroundColor: "rgb(211, 77, 77)"}}>Zamknij teraz</button></>)
        }
        return(
            <>Zakończyła się</>
        )
     }


    if(!contest.id){
       return(
            <span>NotFound</span>
       ) 
    }

    return (
        <div className="contest-tab" style={{marginTop: 0}}>
          <div className="contest-title">
            <h1>
                {contest.contestName}
            </h1>
          </div>
          <div className="container">
          <br />
            <dl className="details-info">
              <div>
              <dd>Nazwa</dd>
              <dt>{contest.contestName}</dt>
              </div>
              <div>
              <dd>Lokalizacja</dd>
              <dt>{contest.localization}</dt>
              </div>
              <div>
              <dd>Start konkursu</dd>
              <dt>{contest.contestStartDate.split('.')[0].replace("T"," ")}</dt>
              </div>
              <div>
              <dd>Uczestnicy / Max</dd>
              <dt><div className="custom-progress-info"><span style={{ color: "rgb(59, 192, 59)" }}>{contest.participantsCount}/{contest.participatesLimit}</span> <ProgressBar striped animated variant="success" now={contest.participantsCount} max={contest.participatesLimit}/></div></dt>
              </div>
              <div>
              <dd>Otwarcie rejestracji</dd>
              <dt>{contest.applicationOpenDate.split('T')[0]}</dt>
              </div>
              <div>
              <dd>Zamknięcie rejestracji</dd>
              <dt>{contest.applicationEndDate.split('T')[0]}</dt>
              </div>
              <div>
              <dd>Status</dd>
              <dt>{contest.status}</dt>
              </div>
              <div>
              <dd>Rejestracja</dd>
              <dt>{contest.status !== "Planned" ? <>{renderRegistrationButtons()}</> : <span style={{color: "rgb(211, 77, 77)", fontSize: 14}}>Konkurs nie jest aktywny</span>}</dt>
              </div>
              <div>
              <dd>Widoczność Zadań</dd>
              <dt>{contest.visibleAssignment ? 'true':'false'}</dt>
              </div>
              <div>
              <dd>Możliwośc przsyłania odpowiedzi</dd>
              <dt>{contest.allowedAnswers ? 'true':'false'}</dt>
              </div>
              <div>
              <dd>Widoczność Leaderboarda</dd>
              <dt>{contest.leaderboardVisible ? 'true':'false'}</dt>
              </div>
              <div>
              <dd>Opis</dd>
              <dt>{contest.description ? parser(contest.description) : ""}</dt>
              </div>
              <div>
              <dd>Leaderboard Opis</dd>
              <dt>{contest.leaderboardDescription ? parser(contest.leaderboardDescription) : ""}</dt>
              </div>
            </dl>
            <Link to={`/adminPanel/contest/edit/${contest.id}`}><button className="custom-button" style={{width: "150px", fontWeight: 600}}>Edytuj Konkurs</button></Link>
            <br />
            <Link to={`/contests/regulation/${contest.id}`} style={{color: "#df9705"}}><button className="custom-button" style={{width: "150px", marginTop: "20px", fontWeight: 600}}>Regulamin Konkursu</button></Link>
            <br />
            <hr />
                
                <div className="options-admin">
                    <h1 onClick={() => setExpanded(!expanded)} aria-controls="collapse-div" aria-expanded={expanded} className="h1--custom">Panel Edycyjny <IoMdArrowDropdown style={{ float: "right", margin: "4px"}} /></h1>
                    
                    <Collapse in={expanded}>
                    <div id="collapse-div">
                    <br />
                    <div className="options-row">
                        <div className="options-item">
                            <p style={{ padding: 15, marginTop: 2, fontWeight: 600}}>Status:</p>
                        </div>
                        <div className="options-item">
                            <p>Zaplanowany</p>
                            {contest.status === "Planned" ? 
                            <button className="checkbox-button activated" aria-label="status konkursu zaplanowany"><ImCheckmark className="button-icon"/></button> 
                            : 
                            <button className={`checkbox-button crossed ${contest.status !== "Planned" ? "disabled-custom" : ""}`} onClick={()=>{setStatus(STATUS.PLANNED)}} aria-label="status konkursu zaplanowany"><ImCross className="button-icon" /></button>
                            }
                        </div>
                        <div className="options-item">
                            <p>Aktywny</p>
                            {contest.status === "Active" ?
                            <button className="checkbox-button activated" aria-label="status konkursu aktywny"><ImCheckmark className="button-icon" /></button>
                            :
                            <button className={`checkbox-button crossed ${contest.status === "Ended" ? "disabled-custom" : ""}`} onClick={()=>{setStatus(STATUS.ACTIVE)}} aria-label="status konkursu aktywny"><ImCross  className="button-icon" /></button>
                            }
                        </div>
                        <div className="options-item">
                            <p>Zakończony</p>
                            {contest.status === "Ended" ?
                            <button className="checkbox-button activated" aria-label="status konkursu zakonczony"><ImCheckmark className="button-icon" /></button>
                            :
                            <button className="checkbox-button crossed" onClick={()=>{setStatus(STATUS.ENDED)}} aria-label="status konkursu zakonczony"><ImCross className="button-icon" /></button>
                            }
                            
                        </div>
                    </div>

                    <div className="options-row">
                        <div className="options-item">
                            <p style={{ padding: 15, marginTop: 2, fontWeight: 600}}>Leadeboard:</p>
                        </div>
                        <div className="options-item">
                            <p>Widoczność</p>
                            {contest.leaderboardVisible ?
                            <button className="checkbox-button" onClick={changeLeaderboardVisibilty} aria-label="widocznosc tabli wynikow true"><ImCheckmark className="button-icon" /></button>
                            :
                            <button className="checkbox-button crossed" onClick={changeLeaderboardVisibilty} aria-label="widocznosc tabli wynikow false"><ImCross  className="button-icon" /></button>
                            }
                        </div>
                    </div>

                    <div className="options-row">
                        <div className="options-item">
                            <p style={{ padding: 15, marginTop: 2, fontWeight: 600}}>Widoczność zadań:</p>
                        </div>
                        <div className="options-item">
                            <p>Widoczność</p>
                            {contest.visibleAssignment ?
                            <button className="checkbox-button" onClick={changeAssignmentVisibility} aria-label="widocznosc zadan true"><ImCheckmark className="button-icon" /></button>
                            :
                            <button className="checkbox-button crossed" onClick={changeAssignmentVisibility} aria-label="widocznosc zadan false"><ImCross  className="button-icon" /></button>
                            }
                        </div>
                    </div>
                    <div className="options-row">
                        <div className="options-item">
                            <p style={{ padding: 15, marginTop: 2, fontWeight: 600}}>Przsyłanie odpowiedzi:</p>
                        </div>
                        <div className="options-item">
                            <p>Przesyłanie</p>
                            {contest.allowedAnswers ?
                            <button className="checkbox-button" onClick={changeAllowAnswers} aria-label="przesylanie odpowiedzi true"><ImCheckmark className="button-icon" /></button>
                            :
                            <button className="checkbox-button crossed" onClick={changeAllowAnswers} aria-label="przesylanie odpowiedzi false"><ImCross  className="button-icon" /></button>
                            }
                        </div>
                    </div>

                    
                    </div>
                    </Collapse>
                </div>
            <hr />
          </div>
          <br />

        <Card>
        <Card.Header>
        <Nav variant="tabs">
            <Nav.Item>
                <Link onClick={() => changeTab("zadania")} className={`nav-link ${detailsTab === "zadania" ? "active-tab" : ""}`} to="" role="tab" id="tab-zadania">Zadania</Link>
            </Nav.Item>
            <Nav.Item>
                <Link onClick={() => changeTab("uczestnicy")} className={`nav-link ${detailsTab === "uczestnicy" ? "active-tab" : ""}`} to="" role="tab" id="tab-uczestnicy">Uczestnicy</Link>
            </Nav.Item>
            <Nav.Item>
                <Link onClick={() => changeTab("leaderboard")} className={`nav-link ${detailsTab === "leaderboard" ? "active-tab" : ""}`} to="" role="tab" id="tab-tablicawynikow">Leaderboard</Link>
            </Nav.Item>
        </Nav>
        </Card.Header>

        {rednerContent()}
        </Card>
        
        </div>
      )
}

