import { useState,useContext,useEffect }  from "react";
import { useParams,useNavigate } from "react-router-dom";
import { Form, Container, Row,Button } from 'react-bootstrap';
import jwtAxios from "../../../utils/jwtAxios";
import UserContext from "../../../context/UserContext";

import {imageUploadCallback} from '../../../utils/richTextEditorImages';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

const EditAssignment = () =>{
    const {contestId} = useParams();
    const [assignments,setAssignments] = useState([])
    const context = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(()=>{
        jwtAxios.get(`/api/Assignment/contest/${contestId}`,{context:context})
        .then(res=> {
            let temp_assignments = res.data;
            let new_temp_assignments = temp_assignments.map((a) => {
                console.log(a.content)
                const blocksFromHtml = htmlToDraft(a.content);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                a.content = editorState;
                return a;
            })
            setAssignments(new_temp_assignments);
        })
        .catch(err => {
            if(err.response.status === 400){
                setAssignments([])
            }
            else if (err.response.status === 404){
                alert(err.response.data)
            }else{
            alert(err)
            }
        })
    },[])

    const handleChange =(e,index)=>{
        const newArrAssignment = [...assignments] // kopiuje tablice
        newArrAssignment[index][e.target.name] = e.target.value //modyfikacja nowej tablicy
        setAssignments(newArrAssignment) // ustawienie nowej tablice w state
    }

    const handleChangeForEditorState = (editorState,index) => {
        const newArrAssignment = [...assignments] // kopiuje tablice
        newArrAssignment[index].content = editorState //modyfikacja nowej tablicy
        setAssignments(newArrAssignment) // ustawienie nowej tablice w state
    }

    const addAssignmentForm = () =>{
        setAssignments(prev => [...prev,{content: EditorState.createEmpty(),points:0,idContest:contestId}])
    }
    
    const removeAssignmentForm = (index) =>{
        const newArrAssignment = [...assignments]
        newArrAssignment.splice(index,1)
        setAssignments(newArrAssignment)
    }
    
    const handleSubmit = (e) =>{
        e.preventDefault();

        let temp_assignments = assignments.map((a) => {
            a.content = draftjsToHtml(convertToRaw(a.content.getCurrentContent()));
            return a;
        })

        jwtAxios.put(`/api/Assignment/${contestId}`,temp_assignments,{context:context})
        .then(res=>navigate(`/adminPanel/contest/${contestId}`))
        .catch(err=>{
            if(err.response.status === 400){
                alert(err.response.data)
            }
            else if(err.response.status === 409){
                alert(err.response.data)
            }
            else if(err.response.status === 404){
                navigate('/*')
            }else{
                console.log(err)
                alert(err)
            }
        })
    } 

    if(assignments.length === 0 ){
        return(<span>Brak Zadań dla tego konkursu</span>)
    }

    return (
        <Container className="form-container register-form">
            <h2>Zadania dla konkursu nr: {contestId}</h2>
            <hr />
            <Form>

                {assignments.map((item,i)=>(
                <div key={i}>
                    <h1>Zadanie: {i+1}</h1>
                        <Form.Group controlId={"form.content"+i}>
                        <Form.Label style={{fontWeight: 600}}>Treść:</Form.Label>
                        <div style={{border: "1px solid lightgray", padding: 10}}>
                        <Editor
                            editorState={item.content}
                            editorStyle={{border: "1px solid", minHeight: "100px", overflow: "auto", maxHeight: "400px"}}
                            toolbar={{
                                image: {
                                    uploadCallback: imageUploadCallback,
                                    previewImage: true,
                                },
                            }}
                            onEditorStateChange={(editorState) => handleChangeForEditorState(editorState,i)}
                        />
                        </div>
                        </Form.Group>
                        <Form.Group controlId={"form.points"+i}>
                        <Row>
                            <Form.Label>Punkty</Form.Label>
                            <Form.Control type="number" name="points" value={item.points} onChange={e=>handleChange(e,i)} ></Form.Control>
                        </Row>
                        </Form.Group>
                        {assignments.length > 1?<Button variant="danger" onClick={()=>removeAssignmentForm(i)}>USUŃ</Button>:null}
                        <hr />
                    </div>
                ))}
                <div>
                <Button variant="secondary" onClick={addAssignmentForm}>Dodaj</Button>
                <br/>
                <button className="custom-button" onClick={handleSubmit}>Zapisz</button>
                </div>
            </Form>
        </Container>)
}

export default EditAssignment

