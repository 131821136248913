
import axios from 'axios'
import React, { useEffect, useState, useContext} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import TextLogo from './elements/TextLogo';
import arrowsvg from '../img/right-arrow-svgrepo-com.svg'; 
import parser from 'html-react-parser';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';


const Home = () => {

  const [activeContest, setActiveContest] = useState({})
  const [loaded, setLoaded] = useState(false);
  const context = useContext(UserContext);
  const [startDateState, setStartDate] = useState("");
  const { userStatus } = context;
  const [isRegistrationFinished,setIsRegistrationFinished] = useState(false);

  useEffect(() => {
    _getContest();
  }, [])

  useEffect(() => {
    let tempintervalId = setInterval(() => {countdown()}, 1000)
    return () => {
      clearInterval(tempintervalId)
    };
  })

  useEffect(() => {
    checkIfRegistrationFinished();
  },[activeContest])

  const checkIfRegistrationFinished = () => {
      var applicationEndDate = new Date(activeContest.applicationEndDate).getTime();
      var today = new Date().getTime();
      if(applicationEndDate < today){
        setIsRegistrationFinished(true);
        console.log(isRegistrationFinished)
      }
  }

  const _getContest = () => {
    axios.get('/api/Contest/active')
    .then(({data}) => {

      const startDate = data.contestStartDate.split("T");
      setStartDate(data.contestStartDate);

      const activeContest = {
        contestName: data.contestName,
        contestStartDate: startDate[0]+" "+startDate[1].split(":")[0]+":"+startDate[1].split(":")[1],
        applicationOpenDate: data.applicationOpenDate.split("T")[0],
        applicationEndDate: data.applicationEndDate.split("T")[0],
        discription: data.description,
        id: data.id,
        localization: data.localization,
        isOpen: data.isRegistrationOpen
      }

      setActiveContest(activeContest);
      setLoaded(true);
    })
    .catch(err => console.log(err))
  }
  
  const countdown = () => {
      var applicationStartDate = new Date(activeContest.applicationOpenDate+"T"+"00:00:00").getTime();
      var applicationEndDate = new Date(activeContest.applicationEndDate+"T"+"00:00:00").getTime();
      var startDate = new Date(startDateState).getTime();
      var today = new Date().getTime();

      let gap;

        const second = 1000;
        const minute = second * 60;
        const hour = minute * 60;
        const day = hour * 24;
        

      if(applicationStartDate > today){
        gap = applicationStartDate - today;
      }
      if(applicationStartDate < today){
        gap = applicationEndDate - today;
      }
      if(applicationEndDate < today){
        gap = startDate - today;
      }

        const textDay = Math.floor(gap / day);
        const textHour = Math.floor((gap % day) / hour);
        const textMinute = Math.floor((gap % hour) / minute);
        const textSecond = Math.floor((gap % minute) / second);

      if(gap > 0){
        document.querySelector(".day").innerHTML = textDay;
        document.querySelector(".hour").innerHTML = textHour;
        document.querySelector(".minute").innerHTML = textMinute;
        document.querySelector(".second").innerHTML = textSecond;
      }      
  }
  
  

  const navigate = useNavigate();

  const registerRedirection = () => {
    console.log(userStatus.isLogged)
    if(userStatus.isLogged){
      navigate("/contest/"+activeContest.id);
    }else{
      navigate("/login");
    }
  }

  if(loaded){
  return (
    <div>
        <div className="jumbotron">
            <div className="container">
                <h1><TextLogo /></h1>
                <p>Konkurs programistyczny.</p>
            </div>
        </div>
                {
                  !activeContest.id ? 
                  (
                  <div className="contest-tab">
                    <div className="container">
                        <h2 style={{fontSize: 28}}>Nie ma aktywnego konkursu.</h2>
                    </div>
                    </div>
                  ) :
                  (

                    <>
                    <div className='home-header'>
                      <div className='home-content'>
                        <div className='countdown'>
                          <div className='container-day'>
                              <h3 className='day'>0</h3>
                              <h3 className='timeindicator'>Dni</h3>
                          </div>
                          <div className='container-hour'>
                              <h3 className='hour'>0</h3>
                              <h3 className='timeindicator'>Godzin</h3>
                          </div>
                          <div className='container-minute'>
                              <h3 className='minute'>0</h3>
                              <h3 className='timeindicator'>Minut</h3>
                          </div>
                          <div className='container-second'>
                              <h3 className='second'>0</h3>
                              <h3 className='timeindicator'>Sekund</h3>
                          </div>
                        </div>

                        <RegistrationBox activeContest={activeContest} isRegistrationFinished={isRegistrationFinished} registerRedirection={registerRedirection} startDateState={startDateState}  />
                        
                      </div>
                    </div>
                    <div className='home-info'>
                          <div className='contest-info-header'>
                              <h2><Link to={`${!userStatus.isAdmin ? `/contest/${activeContest.id}` : `/adminPanel/contest/${activeContest.id}`} `} className="details-link">{activeContest.contestName}</Link></h2>
                          </div>
                          <div className="contest-info">
                                <span style={{fontSize: 12, color: "rgb(186,13,26)"}}>Konkurs odbędzie się:</span>
                                <div className='contest-start'>
                                  {activeContest.contestStartDate}
                                </div>
                                <p>
                                  {activeContest.discription ? parser(activeContest.discription) : ""}
                                </p>
                          </div>
                    </div>
                    <div className='map-container'>
                    <div className='home-map'>
                          <div className='map-text'>
                            <div>
                            <img style={{height: 150, width: 200}} src={arrowsvg} alt="strzałka" />
                            <p>TU JESTEŚMY</p>
                            </div>
                          </div>
                          <div className='map'>
                          <MapContainer center={[52.164205, 22.2724]} zoom={20} scrollWheelZoom={false}>
                          <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker position={[52.164205, 22.2724]}>
                            <Popup>
                              Wydział Nauk Ścisłych i Przyrodniczych UPH. <br />
                              3 Maja 54, 08-110 Siedlce
                            </Popup>
                          </Marker>
                          </MapContainer>
                          </div>
                          <div className='map-localization'>
                              <p>
                                Wydział Nauk Ścisłych i Przyrodniczych UPH.
                              </p>
                              <p>
                                Siedlce 08-110
                              </p>
                              <p>
                                ul. 3 Maja 54
                              </p>

                          </div>
                    </div>
                    </div>
                    </>
                  ) 
                }   
          </div>
  )
  }
  return (
    <div>
      <div className="jumbotron">
            <div className="container">
              <h1><TextLogo /></h1>
              <p>Konkurs programistyczny.</p>
            </div>
        </div>
        <NoActive />
    </div>
  )
}

export const NoActive = () => {
  return (
    <>
          <div className='home-header'>
                <div className='home-content'>
                  <div className='countdown'>
                    <div className='container-day' style={{ width: 500, borderTop: "8px solid #219ebc", padding: 15}}>
                       <span className='countdown-info'>Nie ma obecnie żadnych aktywnych konkursów</span>
                    </div>
                  </div>

                  <div className='registernow-box'>
                        <h3>Historia konkursów</h3>
                          <p>
                            Sprawdź historie naszych konkursów tutaj
                          </p>
                        <Link to="/contests">
                          <button style={{width: "70%"}} className='custom-button home-button'>Historia konkursów</button>
                        </Link>
                  </div>
                </div>
          </div>
          <div className='home-info'>

          </div>
          <div className='map-container'>
            <div className='home-map'>
                  <div className='map-text'>
                    <div>
                    <img style={{height: 150, width: 200}} src={arrowsvg} alt="strzałka" />
                    <p>TU JESTEŚMY</p>
                    </div>
                  </div>
                  <div className='map'>
                  <MapContainer center={[52.16422, 22.27317]} zoom={20} scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[52.16422, 22.27317]}>
                    <Popup>
                      Wydział Nauk Ścisłych i Przyrodniczych UPH. <br />
                      3 Maja 54, 08-110 Siedlce
                    </Popup>
                  </Marker>
                  </MapContainer>
                  </div>
                  <div className='map-localization'>
                      <p>
                        Wydział Nauk Ścisłych i Przyrodniczych UPH.
                      </p>
                      <p>
                        Siedlce 08-110
                      </p>
                      <p>
                        ul. 3 Maja 54
                      </p>

                  </div>
            </div>
          </div>
    </>
  )
}

export const RegistrationBox = ({activeContest,isRegistrationFinished,registerRedirection,startDateState}) => {
  if(activeContest.isOpen && !isRegistrationFinished){
    return (
      <div className='registernow-box'>
          <h3>DO <span style={{textDecoration: "underline red 6px"}}>KOŃCA</span> ZAPISÓW</h3>
            <p>
              <strong>Od:</strong> {activeContest.applicationOpenDate} <strong style={{marginLeft: 30}}>Do:</strong> {activeContest.applicationEndDate}
            </p>
          <button style={{width: "70%"}} onClick={registerRedirection} className='custom-button home-button'>Zapisz się</button>
      </div>
    )
  }
  else if(!activeContest.isOpen && !isRegistrationFinished){
    return (
      <div className='registernow-box'>
          <h3>DO <span style={{textDecoration: "underline red 6px"}}>STARTU</span> ZAPISÓW</h3>
            <p>
              <strong>Od:</strong> {activeContest.applicationOpenDate} <strong style={{marginLeft: 30}}>Do:</strong> {activeContest.applicationEndDate}
            </p>
          <button style={{width: "70%"}} onClick={registerRedirection} className='custom-button home-button'>Zapisz się</button>
      </div>
    )
  }
  else{
    return (
      <div className='registernow-box'>
          <h3>DO <span style={{textDecoration: "underline red 6px"}}>STARTU</span> KONKURSU</h3>
            <p>
              <strong>Start dnia:</strong> {activeContest.contestStartDate}
            </p>
          <button style={{width: "70%"}} onClick={registerRedirection} className='custom-button home-button'>Konkurs tutaj</button>
      </div>
    )
  }
}


export default Home