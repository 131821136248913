import React, { useContext, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import UserContext from '../../../context/UserContext';
import { Form, Container, Row } from 'react-bootstrap';
import jwtAxios from '../../../utils/jwtAxios';

const InstitutionEdit = () => {

    const location = useLocation();
    const {institutionId} = useParams()
    const context = useContext(UserContext)
    const navigate = useNavigate()

    const [institutionName,setInstitutionName] = useState(location.state);
   
    const handleSubmit = (e) => {
        e.preventDefault();
        
        const newInst = {
            id: 0,
            institutionName: institutionName
        }

        jwtAxios.put(`/api/Institution/${institutionId}`,newInst,{context:context})
        .then(() => {
            navigate('/adminPanel/institutions');
        })
        .catch((err) => {
            console.log(err);
        })
    }

  return (
        <Container className="form-container register-form">
            <h2>Edycja instytucji</h2>
            <hr />
            <Form>
                <Form.Group controlId="form.institution">
                    <Row>
                        <Form.Label>Nazwa instytucji</Form.Label>
                        <Form.Control type="text" name="institutionName" value={institutionName} onChange={(e) => setInstitutionName(e.target.value)}></Form.Control>
                    </Row>
                </Form.Group>
                <br />
                <div>
                <button className="custom-button" onClick={handleSubmit}>Zapisz</button>
                </div>
            </Form>
        </Container>
  )
}

export default InstitutionEdit